import { createFileRoute, useNavigate, useRouterState } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import AutomaticSheet from 'src/components/sheet/AutomaticSheet'

import LocationOnIcon from '@mui/icons-material/LocationOn'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import HomeIcon from '@mui/icons-material/Home'
import SchoolIcon from '@mui/icons-material/School'
import StarIcon from '@mui/icons-material/Star'
import WorkIcon from '@mui/icons-material/Work'

import { useTranslation } from 'react-i18next'
import type { DepartureTimeDef } from 'src/components/PlanTimePicker'
import PlanTimePicker from 'src/components/PlanTimePicker'

import { Box, Button } from '@mui/material'
import { useLocalStorage } from '@uidotdev/usehooks'
import { DateTime } from 'luxon'
import ModePicker, { OTPMode } from 'src/components/ModePicker'

interface PlanProps {
    mode: string
    coordinates: string
}

interface ResultProps {
    latitude: number | null
    longitude: number | null
    name: string
}

interface Poi {
    type: string
    name: string
    icon: any
    id: string
    data?: any
}

// Register things for typesafety
declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router
    }
    interface HistoryState {
        originData: ResultProps
        destinationData: ResultProps
        departureTime: DepartureTimeDef
        modes: OTPMode[]
    }
}

export const Route = createFileRoute('/plan/')({
    component: () => Plan(),
})

function Plan() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    // const state = useRouterState();

    const [origin, setOrigin] = useState<ResultProps>({
        latitude: null,
        longitude: null,
        name: t('Select origin'),
    })
    const [destination, setDestination] = useState<ResultProps>({
        latitude: null,
        longitude: null,
        name: t('Select destination'),
    })
    const [planTime, setPlanTime] = useState<DepartureTimeDef>({
        departureOrArrival: 'DEPART',
        datetime: undefined,
        isNow: true,
    })

    const [modes, setModes] = useLocalStorage<string[]>('modes', ['TRANSIT'])

    const [pois, setPois] = useState<Poi[]>([
        {
            type: 'addPoi',
            name: t('Add home'),
            icon: <AddLocationAltIcon />,
            id: 'addPoi',
        },
        {
            type: 'addPoi',
            name: t('Add work'),
            icon: <AddLocationAltIcon />,
            id: 'addPoi',
        },
        {
            type: 'addPoi',
            name: t('Add other location'),
            icon: <AddLocationAltIcon />,
            id: 'addPoi',
        },
    ])

    const state = useRouterState()

    const openLocationPicker = (type: 'origin' | 'destination') => {
    // console.log('gl', departureTime);
        navigate({
            to: `/plan/select/${type}`,
            state: {
                originData: origin,
                destinationData: destination,
                departureTime: planTime,
                modes: modes,
            },
        })
    }

    useEffect(() => {
        console.debug(state?.location?.state)
        if (state?.location.state?.originData) {
            setOrigin(state.location.state.originData)
        }
        if (state?.location.state?.destinationData) {
            setDestination(state.location.state.destinationData)
        }
        if (state?.location.state?.departureTime) {
            setPlanTime(state.location.state.departureTime)
        }
        if (state?.location.state?.modes) {
            setModes(state.location.state.modes)
        }
    }, [state])

    // try to load pois from local storage
    useEffect(() => {
        if (localStorage.getItem('pois')) {
            const pois = loadFavourites()
            pois.push({
                type: 'addPoi',
                name: t('Add POI'),
                icon: <AddLocationAltIcon />,
                id: 'addPoi',
            })
            setPois(pois)
        }
    }, [])

    const doPlan = () => {
        const points = [origin, destination]
        navigate({
            to: '/plan/$mode/$coordinates/$datetime/$type/$page',
            params: {
                mode: modes.join(','),
                coordinates: points.map(p => p.longitude + ',' + p.latitude).join(';'),
                datetime: planTime.isNow ? DateTime.local({ zone: 'Europe/Ljubljana' }).toISO() : planTime.datetime.toISO(),
                type: planTime.departureOrArrival,
                page: 'null',
            },
        })
    }

    return (
        <AutomaticSheet>
            <title>{t('Plan')}</title>
            <Stack spacing={2} paddingX={2}>

                <Typography variant="h6">{t('Where to?')}</Typography>

                <TextField 
                    label={(
                        <div style={{ display: 'flex', alignItems: 'center', color: (origin.latitude !== null ? 'primary.main' : 'secondary.main') }}>
                            <LocationOnIcon />
                            {origin.name}
                        </div>
                    )}
                    onClick={() => openLocationPicker('origin')}
                    variant="outlined"
                    fullWidth
                />

                <TextField
                    label={(
                        <div style={{ display: 'flex', alignItems: 'center', color: (destination.latitude !== null ? 'primary.main' : 'secondary.main') }}>
                            <LocationOnIcon />
                            {destination.name}
                        </div>
                    )}
                    onClick={() => openLocationPicker('destination')}
                    variant="outlined"
                    fullWidth
                />

                <Stack direction="row" justifyContent="space-between">

                    <ModePicker
                        onChange={(newModes) => {
                            setModes(newModes)
                            console.debug(modes, newModes)
                        }}
                        modes={modes}
                    />
                    <Button
                        variant="contained"
                        size="large"
                        endIcon={<ChevronRightIcon />}
                        onClick={doPlan}
                        disableElevation
                        disabled={origin.latitude == null || destination.latitude == null}
                    >
                        {t('Go')}
                    </Button>

                </Stack>

                <PlanTimePicker value={planTime} onChange={setPlanTime} />

                <Divider sx={{ width: '100%' }} />

                <Typography variant="h6">{t('Points of interest')}</Typography>

                <Box sx={{ width: '100%', overflowX: 'auto' }}>
                    <Stack direction="row" spacing={1}>
                        {pois.map((poi, index) => (
                            <Chip
                                key={index}
                                label={poi.name}
                                icon={poi.icon}
                                onClick={() => {
                                    if (poi.type === 'addPoi') {
                                        navigate({
                                            to: '/plan/poi',
                                        })
                                    }
                                    else {
                                    // get current location of user
                                        navigator.geolocation.getCurrentPosition((position) => {
                                            const coords = position.coords;

                                            const points = [
                                                { longitude: coords.longitude, latitude: coords.latitude }, {longitude: poi.data.lon, latitude: poi.data.lat},
                                            ]
                                            console.log('points', points, points.map(p => p.longitude + ',' + p.latitude).join(';'))
                                            navigate({
                                                to: '/plan/$mode/$coordinates/$datetime/$type/$page',
                                                params: {
                                                    mode: modes.join(','),
                                                    coordinates: points.map(p => p.longitude + ',' + p.latitude).join(';'),
                                                    datetime: planTime.isNow ? DateTime.local({ zone: 'Europe/Ljubljana' }).toISO() : planTime.datetime.toISO(),
                                                    type: planTime.departureOrArrival,
                                                    page: 'null',
                                                },
                                            })
                                        // to: `/plan/${modes.join(',')}/${coords.longitude},${coords.latitude};${poi.data.lon},${poi.data.lat}/${planTime.datetime}/${planTime.departureOrArrival}/null`
                                        })
                                    }
                                }}
                            />
                        ))}
                    </Stack>
                </Box>
            </Stack>
        </AutomaticSheet>
    )
}
function loadFavourites() {
    const pois = JSON.parse(localStorage.getItem('pois')!)
    // iterate over the pois and fix the icons
    pois.forEach((poi: Poi) => {
        switch (poi.icon) {
            case 'home':
                poi.icon = <HomeIcon />
                break
            case 'work':
                poi.icon = <WorkIcon />
                break
            case 'school':
                poi.icon = <SchoolIcon />
                break
            case 'workout':
                poi.icon = <FitnessCenterIcon />
                break
            case 'star':
                poi.icon = <StarIcon />
                break
            default:
                poi.icon = <AddLocationAltIcon />
                break
        }
    })
    return pois
}
