enum PoiType {
    TRANSIT_STOP, MICROMOBILITY_STOP, ADDRESS,
}

enum PoiSubType {
    BUS_STOP, RAIL_STOP, BIKESHARE_STOP, SCOOTER_STOP, CARSHARE_STOP,
}

export interface POI {
    id: string
    title: string
    lat: number
    lng: number
    type: PoiType
    subtype?: PoiSubType
    provider?: string
}

export function getFavourites(): POI[] {
    const storedPOIs = localStorage.getItem('favourites') || '[]'
    const parsedPOIs = JSON.parse(storedPOIs)
    return parsedPOIs
}
