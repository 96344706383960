import AddLocationIcon from '@mui/icons-material/AddLocation'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import HomeIcon from '@mui/icons-material/Home'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import SchoolIcon from '@mui/icons-material/School'
import StarIcon from '@mui/icons-material/Star'
import WorkIcon from '@mui/icons-material/Work'
import { Box, IconButton, List, ListItem, ListItemButton, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import maplibregl from 'maplibre-gl'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchSelect } from 'src/components/SearchSelect'
import AutomaticSheet from 'src/components/sheet/AutomaticSheet'
import { MapRenderContext } from 'src/contexts/MapRenderContext'

export const Route = createFileRoute('/plan/poi/')({
    component: SelectPoi,
})

function SelectPoi() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { map } = useContext(MapRenderContext)

    const [selectedIcon, setSelectedIcon] = useState('home')
    const [tab, setTab] = useState('map')
    const [poiName, setQuery] = useState('')
    const [marker, setMarker] = useState(null)

    const iconList = [
        { name: 'home', component: <HomeIcon />, defaultName: t('Home') },
        { name: 'work', component: <WorkIcon />, defaultName: t('Work') },
        { name: 'school', component: <SchoolIcon />, defaultName: t('School') },
        { name: 'workout', component: <FitnessCenterIcon />, defaultName: t('Workout') },
        { name: 'star', component: <StarIcon />, defaultName: t('Other') },
    ]

    useEffect(() => {
        const mapCenter = map.current.getCenter()
        const newMarker = new maplibregl.Marker().setLngLat(mapCenter).addTo(map.current)

        const updateMarkerPosition = () => {
            const currentCenter = map.current.getCenter()
            newMarker.setLngLat(currentCenter)
        }

        map.current.on('move', updateMarkerPosition)
        map.current.on('zoom', updateMarkerPosition)

        setMarker(newMarker)

        return () => {
            newMarker.remove()
            map.current.off('move', updateMarkerPosition)
            map.current.off('zoom', updateMarkerPosition)
        }
    }, [map])

    const confirmPoi = () => {
        if (!marker) return

        const position = map.current.getCenter()

        // remove marker
        marker.remove()
        map.current.removeLayer(marker)

        const poisList = JSON.parse(localStorage.getItem('pois') || '[]')

        poisList.push({
            type: 'poi',
            name: poiName,
            icon: selectedIcon,
            data: {
                lat: position.lat,
                lon: position.lng,
            },
        })

        localStorage.setItem('pois', JSON.stringify(poisList))

        navigate({
            to: '/plan',
        })
    }

    const toggleTab = (event, newValue) => {
        if (newValue) {
            setTab(newValue)
        }
    }

    return (
        <AutomaticSheet>
            <List style={{ padding: 0, paddingTop: '4px', width: '100%' }}>
                <ListItem>
                    <ToggleButtonGroup
                        value={tab}
                        exclusive
                        size="small"
                        onChange={toggleTab}
                        fullWidth
                        sx={{ justifyContent: 'center', color: 'primary.main' }}
                    >
                        <ToggleButton value="map">{t('Select on map')}</ToggleButton>
                        <ToggleButton value="search">{t('Search')}</ToggleButton>
                    </ToggleButtonGroup>
                </ListItem>
            </List>

            {tab === 'map' && (
                <List style={{ padding: 0, width: '100%' }}>
                    <ListItem>
                        <TextField
                            variant="outlined"
                            placeholder={t('Enter the name of the location')}
                            fullWidth
                            value={poiName}
                            onChange={event => setQuery(event.target.value)}
                            sx={{ marginBottom: '1rem' }}
                        />
                    </ListItem>
                    <ListItem>
                        <Typography variant="h6">{t('Select icon')}</Typography>
                    </ListItem>
                    <ListItem>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            {iconList.map(icon => (
                                <IconButton
                                    key={icon.name}
                                    onClick={() => {
                                        if (
                                            (poiName === '' && poiName !== icon.defaultName)
                                            || iconList.some(i => i.defaultName === poiName)
                                        ) {
                                            setQuery(icon.defaultName)
                                        }
                                        setSelectedIcon(icon.name)
                                    }}
                                    sx={{
                                        'backgroundColor':
                                            selectedIcon === icon.name
                                                ? 'primary.main'
                                                : 'transparent',
                                        'color': selectedIcon === icon.name ? 'white' : 'primary.main',
                                        '&:hover': {
                                            backgroundColor: 'primary.main',
                                            color: 'white',
                                        },
                                    }}
                                >
                                    {icon.component}
                                </IconButton>
                            ))}
                        </Box>
                    </ListItem>

                    <ListItemButton onClick={confirmPoi} sx={{ height: '4rem' }} disabled={poiName.length == 0}>
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <AddLocationIcon sx={{ color: 'primary.main' }} />
                            <Typography variant="body1">{t('Confirm selected location')}</Typography>
                            <NavigateNextIcon sx={{ color: 'primary.main', marginLeft: 'auto' }} />
                        </Box>
                    </ListItemButton>

                </List>
            )}

            {tab === 'search' && (
                <SearchSelect onSelect={(result) => {
                    map?.current!.flyTo({
                        center: [result.location?.longitude, result.location?.latitude],
                        zoom: 15,
                    })
                    setTab('map')
                }}
                />
            )}
        </AutomaticSheet>
    )
}
