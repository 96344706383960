import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/tickets')({
    component: Tickets,
})

function Tickets() {
    return (
        <div className="p-2">
            <h3>Welcome Home!</h3>
        </div>
    )
}
