import { createFileRoute, useNavigate, useRouterState } from '@tanstack/react-router'
import { useCallback } from 'react'
import AutomaticSheet from 'src/components/sheet/AutomaticSheet'

import { useTranslation } from 'react-i18next'
import { Result, SearchSelect } from 'src/components/SearchSelect'

export const Route = createFileRoute('/plan/select/$selectType')({
    component: PlanSelectPoint,
})

function PlanSelectPoint() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const state = useRouterState()

    const selectType = Route.useParams({
        select: params => params.selectType,
    })

    const onClick = useCallback((result: Result) => {
        const selected = { latitude: result.location?.latitude, longitude: result.location?.longitude, name: result.title, type: selectType }
        const oldState = state?.location.state || {}
        const newState = { ...oldState }
        newState[selectType + 'Data'] = selected

        console.debug('newState', newState)
        console.debug('selected', selected)
        navigate({
            to: `/plan`,
            state: newState,
        })
    }, [selectType])

    return (
        <AutomaticSheet>
            <title>{t('Select location')}</title>
            <SearchSelect onSelect={onClick} />
        </AutomaticSheet>
    )
}
