import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import LocalParkingIcon from '@mui/icons-material/LocalParking'
import {
    Box,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Switch,
    Typography,
} from '@mui/material'
import { createFileRoute } from '@tanstack/react-router'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AutomaticSheet from 'src/components/sheet/AutomaticSheet'
import { MapRenderContext } from 'src/contexts/MapRenderContext'

export const Route = createFileRoute('/filter')({
    component: FilterComponent,
})

function FilterComponent() {
    const { t } = useTranslation()
    const { map } = useContext(MapRenderContext)

    const localStorageKey = 'mapFilterOptions'

    const [checkedOptions, setCheckedOptions] = useState({})

    const publicTransportHandler = (id, isOn) => {
        id = id.toLowerCase()
        map?.current?.getLayer(`stops-circles-${id}`)?.setLayoutProperty('visibility', isOn ? 'visible' : 'none')
        map?.current?.getLayer(`stops-symbol-${id}`)?.setLayoutProperty('visibility', isOn ? 'visible' : 'none')
        console.log(
            'id:', id,
            'isOn:', isOn,
            'stops-circles:', map?.current?.getLayer(`stops-circles-${id}`),
            'stops-symbol:', map?.current?.getLayer(`stops-symbol-${id}`),
        )
        // force redraw
        map?.current?.redraw()
    }

    const micromobilityHandler = (id, isOn) => {
        const layers = map.current.getStyle().layers.filter(layer => layer.id.includes(id))
        layers.forEach((layer) => {
            map?.current?.setLayoutProperty(layer.id, 'visibility', isOn ? 'visible' : 'none')
            map?.current?.redraw()
        })
    }

    const parkingHandler = (id, isOn) => {
        map.current.getLayer('parkings-circles')?.setLayoutProperty('visibility', isOn ? 'visible' : 'none')
        map.current?.redraw()
    }

    const options = [
        {
            name: t('Public transport'),
            icon: <DirectionsBusIcon />,
            handler: publicTransportHandler,
            items: [
                { name: 'LPP', id: 'LPP' },
                { name: 'IJPP', id: 'IJPP' },
                { name: 'Marprom', id: 'MARPROM' },
                { name: 'Celebus', id: 'CELEBUS' },
                { name: 'Arriva Mestni prometi', id: 'ARRIVAMP' },
            ],
        },
        {
            name: t('Micromobility'),
            icon: <DirectionsBikeIcon />,
            handler: micromobilityHandler,
            items: [
                { name: 'Bicikelj', id: 'jcdecaux_ljubljana' },
                { name: 'MBajk', id: 'jcdecaux_maribor' },
                { name: 'Nomago Bikes', id: 'nextbike' },
                { name: 'SCBikes', id: 'scbikes' },
                { name: 'Avant2Go', id: 'avant2go' },
                { name: 'Share\'ngo', id: 'sharengo' },
                { name: 'GreenGo', id: 'greengo' },
                { name: 'Kvik', id: 'kvik' },
            ],
        },
        {
            name: t('Parking'),
            icon: <LocalParkingIcon />,
            handler: parkingHandler,
            items: [{ name: 'Parking', id: 'parking' }],
        },
    ]

    useEffect(() => {
        const savedState = localStorage.getItem(localStorageKey)
        if (savedState) {
            setCheckedOptions(JSON.parse(savedState))
        }
        else {
            const initialState = {}
            options.forEach((group) => {
                group.items.forEach((item) => {
                    initialState[item.id] = true // Default all to "on"
                })
            })
            setCheckedOptions(initialState)
        }
    }, [])

    const handleToggle = (group, item) => {
        setCheckedOptions((prev) => {
            const updatedState = { ...prev, [item.id]: !prev[item.id] }

            if (group.handler) {
                group.handler(item.id, !prev[item.id])
            }

            localStorage.setItem(localStorageKey, JSON.stringify(updatedState))
            return updatedState
        })
    }

    const handleGroupToggle = (group) => {
        const allChecked = group.items.every(item => checkedOptions[item.id])

        setCheckedOptions((prev) => {
            const updatedState = { ...prev }

            group.items.forEach((item) => {
                updatedState[item.id] = !allChecked

                if (group.handler) {
                    group.handler(item.id, !allChecked)
                }
            })

            localStorage.setItem(localStorageKey, JSON.stringify(updatedState))
            return updatedState
        })
    }

    return (
        <AutomaticSheet>
            <title>{t('Filters')}</title>
            <Box sx={{ padding: 2 }}>
                <Typography variant="h5">{t('Filters')}</Typography>
                <Divider />
                <List>
                    {options.map((group, groupIndex) => (
                        <div key={groupIndex}>
                            <ListItemButton onClick={() => handleGroupToggle(group)}>
                                <ListItemIcon>{group.icon}</ListItemIcon>
                                <ListItemText primary={group.name} sx={{ fontSize: 18 }} />
                                <Switch
                                    checked={group.items.every(item => checkedOptions[item.id])}
                                    indeterminate={
                                        group.items.some(item => checkedOptions[item.id])
                                        && !group.items.every(item => checkedOptions[item.id])
                                    }
                                />
                            </ListItemButton>
                            {group.items.map(item => (
                                <ListItemButton
                                    key={item.id}
                                    onClick={() => handleToggle(group, item)}
                                >
                                    <ListItemText primary={item.name} />
                                    <Switch
                                        edge="start"
                                        checked={!!checkedOptions[item.id]}
                                        disableRipple
                                    />
                                </ListItemButton>
                            ))}
                            {groupIndex < options.length - 1 && <Divider />}
                        </div>
                    ))}
                </List>
            </Box>
        </AutomaticSheet>
    )
}
