import { Stack, Typography } from '@mui/material'

import fontColorContrast from 'font-color-contrast'
import { Leg } from 'src/generated/brezavta/requests/types.gen'
import { getColorForMode, getForegroundColorForMode, getIconForMode } from 'src/lib/modes'

function toMinutes(seconds: number) {
    return Math.ceil(seconds / 60 / 1000) + ' min'
}

function calculateWidth(planLength: number, legLength: number) {
    return (legLength / planLength) * 100
}
function PlanItinerary({ index, leg, planLength, legCount }: { index: number, leg: Leg, planLength: number, legCount: number }) {
    const commonStyles = {
        flex: calculateWidth(planLength, leg.end_time - leg.start_time),
        paddingTop: '3px',
        paddingBottom: '3px',
        textWrap: 'no-wrap',
        minWidth: '3.5rem',
    }

    const typographyStyles = {
        marginLeft: '2px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }

    let text = null as any
    if (leg.mode === 'WALK' && toMinutes(leg.end_time - leg.start_time) < 5 && legCount > 5) {
        return (
            <></>
        )
    }

    const backgroundColor = getColorForMode(leg)
    const textColor = getForegroundColorForMode(leg) || fontColorContrast(backgroundColor)
    const icon = getIconForMode(leg)

    if (leg.mode === 'WALK') {
        text = toMinutes(leg.end_time - leg.start_time)
    }
    else if (leg.mode === 'BUS' || leg.mode === 'RAIL') {
        text = leg.trip!.short_name
    }
    else if (leg.mode === 'BICYCLE') {
        text = toMinutes(leg.end_time - leg.start_time)
    }
    else {
        text = toMinutes(leg.end_time - leg.start_time)
    }

    let borderRadius = undefined
    if (index == 0) borderRadius = '5px 0 0 5px'
    else if (index == legCount - 1) borderRadius = '0 5px 5px 0'

    return (
        <Stack direction="row" sx={{ backgroundColor, color: textColor, borderRadius, ...commonStyles }}>
            {icon}
            <Typography variant="body1" sx={typographyStyles}>{text}</Typography>
        </Stack>
    )
}

function PlanDisplay(plan: Plan) {
    const planLength = plan.plan.legs.map(leg => leg.end_time - leg.start_time).reduce((partialSum, a) => partialSum + a, 0)
    return (
        <Stack direction="row" spacing={0} sx={{ width: '100%', display: 'flex' }}>
            {plan.plan.legs.map((leg, index) => (
                <PlanItinerary key={index} index={index} leg={leg} planLength={planLength} legCount={plan.plan.legs.length} />
            ))}
        </Stack>
    )
}

export default PlanDisplay
