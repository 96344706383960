import CarRentalIcon from '@mui/icons-material/CarRental'
import ElectricScooterIcon from '@mui/icons-material/ElectricScooter'

import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'

import { IconButton, Stack, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useTheme } from '@mui/material/styles'

export type OTPMode = 'TRANSIT' | 'BICYCLE_RENT' | 'SCOOTER_RENT' | 'CAR_RENT'

type ModePickerProps = {
    modes: OTPMode[]
    onChange: (newModes: OTPMode[]) => void
}

const allModes = [
    { title: 'Transit', key: 'TRANSIT', icon: DirectionsBusIcon },
    { title: 'Bike rental', key: 'BICYCLE_RENT', icon: DirectionsBikeIcon },
    { title: 'Scooter rental', key: 'SCOOTER_RENT', icon: ElectricScooterIcon },
    { title: 'Car rental', key: 'CAR_RENT', icon: CarRentalIcon },
]

export default function ModePicker({ modes, onChange }: ModePickerProps) {
    const theme = useTheme()
    const { t } = useTranslation()

    return (
        <Stack direction="row" spacing={1}>
            {allModes.map(mode => (
                <Tooltip title={t(mode.title)} key={mode.key}>
                    <IconButton
                        onClick={() => {
                            const index = modes.indexOf(mode.key)
                            if (index > -1)
                                modes.splice(index, 1)
                            else
                                modes.push(mode.key)
                            onChange([...modes])
                        }}
                        sx={{
                            'backgroundColor': modes.includes(mode.key) ? theme.palette.primary.main : 'default',
                            'color': modes.includes(mode.key) ? '#fff' : theme.palette.text.primary,
                            '&:hover': {
                                backgroundColor: modes.includes(mode.key) ? theme.palette.primary.dark : theme.palette.action.hover,
                            },
                        }}
                    >
                        <mode.icon />
                    </IconButton>
                </Tooltip>
            ))}
        </Stack>
    )
}
