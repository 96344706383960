import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import BusAlertIcon from '@mui/icons-material/BusAlert'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Stack,
    Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function extractUniqueAlerts(tripData, lang) {
    const alerts = []

    tripData.stop_times.forEach((stopTime) => {
        if (!stopTime.alerts) return

        stopTime.alerts.forEach((alert) => {
            if (alert.language === lang && !alerts.find(a => a.id === alert.id)) {
                alerts.push(alert)
            }
        })
    })

    return alerts
}

function Alert({ alert }) {
    return (
        <div>
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Stack direction="column">
                    <Typography variant="h6">{alert.header}</Typography>
                    <Typography>{alert.description}</Typography>
                </Stack>
                {
                    alert.url && (
                        <OpenInNewIcon sx={{ color: 'primary.main', cursor: 'pointer' }} onClick={() => window.open(alert.url)} />
                    )
                }

            </Stack>
        </div>
    )
}

function AlertDrawer({ tripData }) {
    const [currentAlertIndex, setCurrentAlertIndex] = useState(0)
    const [language, setLanguage] = useState('en')
    const { t, i18n } = useTranslation()

    useEffect(() => {
        setLanguage(i18n.language)
    }, [i18n])

    const alerts = extractUniqueAlerts(tripData, language)

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentAlertIndex(prevIndex => (prevIndex + 1) % alerts.length)
        }, 3000)

        return () => clearInterval(intervalId)
    }, [alerts])

    const currentAlert = alerts[currentAlertIndex]

    if (alerts.length === 0) {
        return null
    }

    return (
        <div style={{ width: '100%' }}>
            <Accordion>
                <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                    <Stack direction="row" spacing={1}>
                        <BusAlertIcon sx={{ color: 'orange' }} />
                        <Typography>{currentAlert?.header}</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    {alerts.map((alert, index) => (
                        <Alert key={index} alert={alert} />
                    ))}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default AlertDrawer
