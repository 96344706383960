import MenuIcon from '@mui/icons-material/Menu'
import RouteIcon from '@mui/icons-material/Route'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { useNavigate, useRouterState } from '@tanstack/react-router'
import React from 'react'

import { useTranslation } from 'react-i18next'

const Navbar: React.FC = () => {
    const router = useRouterState()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const handleNavigationChange = (_: unknown, newValue: string) => {
        navigate({ to: newValue })
    }

    return (
        <BottomNavigation
            showLabels
            value={router.location.pathname}
            onChange={handleNavigationChange}
            style={{
                paddingBottom: 'env(safe-area-inset-bottom)',
            }}
        >
            {/* <BottomNavigationAction
                label={t("Info")}
                value="/info"
                disabled
                style={{ opacity: 0.5 }}
                icon={<InfoIcon />}
            /> */}
            {/* <BottomNavigationAction
                label={t("Tickets")}
                value="/tickets"
                icon={<BookOnlineIcon />}
                disabled
                style={{ opacity: 0.5 }}
            /> */}
            <BottomNavigationAction
                label={t('Plan')}
                value="/plan"
                icon={<RouteIcon />}
            />
            <BottomNavigationAction
                label={t('Settings')}
                value="/settings"
                icon={<MenuIcon />}
            />
        </BottomNavigation>
    )
}

export default Navbar
