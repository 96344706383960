// generated with @7nohe/openapi-react-query-codegen@2.0.0-beta.3 

import { type Options } from "@hey-api/client-fetch";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getMicromobilityDetailMicromobilityMicromobilityIdGet, getMicromobilityMicromobilityGet, getParkingParkingGet, getPlanPlanTransportModesFromLonLatToLonLatGet, getStopStopsStopIdGet, getTripGeometryTripsTripIdGeometryGet, getTripTripsTripIdGet, getTripWithGeometryTripsTripIdUiGet, getVehicleLocationsTripsTripIdVehiclesGet, listArrivalsStopsStopIdArrivalsGet, listScheduleStopsStopIdScheduleDateGet, listStopsStopsGet, listVehiclesVehiclesLocationsGet, reverseGeocodeGeocodingReverseGeocodeGet, robotsRobotsTxtGet, searchGeocodingSearchGet } from "../requests/services.gen";
import { GetMicromobilityDetailMicromobilityMicromobilityIdGetData, GetMicromobilityDetailMicromobilityMicromobilityIdGetError, GetMicromobilityMicromobilityGetError, GetParkingParkingGetError, GetPlanPlanTransportModesFromLonLatToLonLatGetData, GetPlanPlanTransportModesFromLonLatToLonLatGetError, GetStopStopsStopIdGetData, GetStopStopsStopIdGetError, GetTripGeometryTripsTripIdGeometryGetData, GetTripGeometryTripsTripIdGeometryGetError, GetTripTripsTripIdGetData, GetTripTripsTripIdGetError, GetTripWithGeometryTripsTripIdUiGetData, GetTripWithGeometryTripsTripIdUiGetError, GetVehicleLocationsTripsTripIdVehiclesGetData, GetVehicleLocationsTripsTripIdVehiclesGetError, ListArrivalsStopsStopIdArrivalsGetData, ListArrivalsStopsStopIdArrivalsGetError, ListScheduleStopsStopIdScheduleDateGetData, ListScheduleStopsStopIdScheduleDateGetError, ListStopsStopsGetError, ListVehiclesVehiclesLocationsGetError, ReverseGeocodeGeocodingReverseGeocodeGetData, ReverseGeocodeGeocodingReverseGeocodeGetError, RobotsRobotsTxtGetError, SearchGeocodingSearchGetData, SearchGeocodingSearchGetError } from "../requests/types.gen";
import * as Common from "./common";
export const useGetParkingParkingGet = <TData = Common.GetParkingParkingGetDefaultResponse, TError = GetParkingParkingGetError, TQueryKey extends Array<unknown> = unknown[]>(clientOptions: Options<unknown, true> = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseGetParkingParkingGetKeyFn(clientOptions, queryKey), queryFn: () => getParkingParkingGet({ ...clientOptions }).then(response => response.data as TData) as TData, ...options });
export const useGetMicromobilityMicromobilityGet = <TData = Common.GetMicromobilityMicromobilityGetDefaultResponse, TError = GetMicromobilityMicromobilityGetError, TQueryKey extends Array<unknown> = unknown[]>(clientOptions: Options<unknown, true> = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseGetMicromobilityMicromobilityGetKeyFn(clientOptions, queryKey), queryFn: () => getMicromobilityMicromobilityGet({ ...clientOptions }).then(response => response.data as TData) as TData, ...options });
export const useGetMicromobilityDetailMicromobilityMicromobilityIdGet = <TData = Common.GetMicromobilityDetailMicromobilityMicromobilityIdGetDefaultResponse, TError = GetMicromobilityDetailMicromobilityMicromobilityIdGetError, TQueryKey extends Array<unknown> = unknown[]>(clientOptions: Options<GetMicromobilityDetailMicromobilityMicromobilityIdGetData, true>, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseGetMicromobilityDetailMicromobilityMicromobilityIdGetKeyFn(clientOptions, queryKey), queryFn: () => getMicromobilityDetailMicromobilityMicromobilityIdGet({ ...clientOptions }).then(response => response.data as TData) as TData, ...options });
export const useGetTripTripsTripIdGet = <TData = Common.GetTripTripsTripIdGetDefaultResponse, TError = GetTripTripsTripIdGetError, TQueryKey extends Array<unknown> = unknown[]>(clientOptions: Options<GetTripTripsTripIdGetData, true>, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseGetTripTripsTripIdGetKeyFn(clientOptions, queryKey), queryFn: () => getTripTripsTripIdGet({ ...clientOptions }).then(response => response.data as TData) as TData, ...options });
export const useGetTripGeometryTripsTripIdGeometryGet = <TData = Common.GetTripGeometryTripsTripIdGeometryGetDefaultResponse, TError = GetTripGeometryTripsTripIdGeometryGetError, TQueryKey extends Array<unknown> = unknown[]>(clientOptions: Options<GetTripGeometryTripsTripIdGeometryGetData, true>, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseGetTripGeometryTripsTripIdGeometryGetKeyFn(clientOptions, queryKey), queryFn: () => getTripGeometryTripsTripIdGeometryGet({ ...clientOptions }).then(response => response.data as TData) as TData, ...options });
export const useGetTripWithGeometryTripsTripIdUiGet = <TData = Common.GetTripWithGeometryTripsTripIdUiGetDefaultResponse, TError = GetTripWithGeometryTripsTripIdUiGetError, TQueryKey extends Array<unknown> = unknown[]>(clientOptions: Options<GetTripWithGeometryTripsTripIdUiGetData, true>, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseGetTripWithGeometryTripsTripIdUiGetKeyFn(clientOptions, queryKey), queryFn: () => getTripWithGeometryTripsTripIdUiGet({ ...clientOptions }).then(response => response.data as TData) as TData, ...options });
export const useGetVehicleLocationsTripsTripIdVehiclesGet = <TData = Common.GetVehicleLocationsTripsTripIdVehiclesGetDefaultResponse, TError = GetVehicleLocationsTripsTripIdVehiclesGetError, TQueryKey extends Array<unknown> = unknown[]>(clientOptions: Options<GetVehicleLocationsTripsTripIdVehiclesGetData, true>, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseGetVehicleLocationsTripsTripIdVehiclesGetKeyFn(clientOptions, queryKey), queryFn: () => getVehicleLocationsTripsTripIdVehiclesGet({ ...clientOptions }).then(response => response.data as TData) as TData, ...options });
export const useListVehiclesVehiclesLocationsGet = <TData = Common.ListVehiclesVehiclesLocationsGetDefaultResponse, TError = ListVehiclesVehiclesLocationsGetError, TQueryKey extends Array<unknown> = unknown[]>(clientOptions: Options<unknown, true> = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseListVehiclesVehiclesLocationsGetKeyFn(clientOptions, queryKey), queryFn: () => listVehiclesVehiclesLocationsGet({ ...clientOptions }).then(response => response.data as TData) as TData, ...options });
export const useListStopsStopsGet = <TData = Common.ListStopsStopsGetDefaultResponse, TError = ListStopsStopsGetError, TQueryKey extends Array<unknown> = unknown[]>(clientOptions: Options<unknown, true> = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseListStopsStopsGetKeyFn(clientOptions, queryKey), queryFn: () => listStopsStopsGet({ ...clientOptions }).then(response => response.data as TData) as TData, ...options });
export const useGetStopStopsStopIdGet = <TData = Common.GetStopStopsStopIdGetDefaultResponse, TError = GetStopStopsStopIdGetError, TQueryKey extends Array<unknown> = unknown[]>(clientOptions: Options<GetStopStopsStopIdGetData, true>, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseGetStopStopsStopIdGetKeyFn(clientOptions, queryKey), queryFn: () => getStopStopsStopIdGet({ ...clientOptions }).then(response => response.data as TData) as TData, ...options });
export const useListArrivalsStopsStopIdArrivalsGet = <TData = Common.ListArrivalsStopsStopIdArrivalsGetDefaultResponse, TError = ListArrivalsStopsStopIdArrivalsGetError, TQueryKey extends Array<unknown> = unknown[]>(clientOptions: Options<ListArrivalsStopsStopIdArrivalsGetData, true>, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseListArrivalsStopsStopIdArrivalsGetKeyFn(clientOptions, queryKey), queryFn: () => listArrivalsStopsStopIdArrivalsGet({ ...clientOptions }).then(response => response.data as TData) as TData, ...options });
export const useListScheduleStopsStopIdScheduleDateGet = <TData = Common.ListScheduleStopsStopIdScheduleDateGetDefaultResponse, TError = ListScheduleStopsStopIdScheduleDateGetError, TQueryKey extends Array<unknown> = unknown[]>(clientOptions: Options<ListScheduleStopsStopIdScheduleDateGetData, true>, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseListScheduleStopsStopIdScheduleDateGetKeyFn(clientOptions, queryKey), queryFn: () => listScheduleStopsStopIdScheduleDateGet({ ...clientOptions }).then(response => response.data as TData) as TData, ...options });
export const useSearchGeocodingSearchGet = <TData = Common.SearchGeocodingSearchGetDefaultResponse, TError = SearchGeocodingSearchGetError, TQueryKey extends Array<unknown> = unknown[]>(clientOptions: Options<SearchGeocodingSearchGetData, true>, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseSearchGeocodingSearchGetKeyFn(clientOptions, queryKey), queryFn: () => searchGeocodingSearchGet({ ...clientOptions }).then(response => response.data as TData) as TData, ...options });
export const useReverseGeocodeGeocodingReverseGeocodeGet = <TData = Common.ReverseGeocodeGeocodingReverseGeocodeGetDefaultResponse, TError = ReverseGeocodeGeocodingReverseGeocodeGetError, TQueryKey extends Array<unknown> = unknown[]>(clientOptions: Options<ReverseGeocodeGeocodingReverseGeocodeGetData, true>, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseReverseGeocodeGeocodingReverseGeocodeGetKeyFn(clientOptions, queryKey), queryFn: () => reverseGeocodeGeocodingReverseGeocodeGet({ ...clientOptions }).then(response => response.data as TData) as TData, ...options });
export const useGetPlanPlanTransportModesFromLonLatToLonLatGet = <TData = Common.GetPlanPlanTransportModesFromLonLatToLonLatGetDefaultResponse, TError = GetPlanPlanTransportModesFromLonLatToLonLatGetError, TQueryKey extends Array<unknown> = unknown[]>(clientOptions: Options<GetPlanPlanTransportModesFromLonLatToLonLatGetData, true>, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseGetPlanPlanTransportModesFromLonLatToLonLatGetKeyFn(clientOptions, queryKey), queryFn: () => getPlanPlanTransportModesFromLonLatToLonLatGet({ ...clientOptions }).then(response => response.data as TData) as TData, ...options });
export const useRobotsRobotsTxtGet = <TData = Common.RobotsRobotsTxtGetDefaultResponse, TError = RobotsRobotsTxtGetError, TQueryKey extends Array<unknown> = unknown[]>(clientOptions: Options<unknown, true> = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseRobotsRobotsTxtGetKeyFn(clientOptions, queryKey), queryFn: () => robotsRobotsTxtGet({ ...clientOptions }).then(response => response.data as TData) as TData, ...options });
