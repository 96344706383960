import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined'
import { Box, ListItemButton, useTheme } from '@mui/material'
import List from '@mui/material/List'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { DateTime } from 'luxon'
import { useEffect, useRef, useState } from 'react'

import DepartureBoardIcon from '@mui/icons-material/DepartureBoard'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import SensorsIcon from '@mui/icons-material/Sensors'

import { useNavigate } from '@tanstack/react-router'

import { useTranslation } from 'react-i18next'

import type { FormatType } from 'src/lib/util'
import { formatTime } from 'src/lib/util'

function formatDelay(seconds_arrival: number) {
    if (Math.abs(seconds_arrival) <= 29) {
        return ''
    }
    else if (seconds_arrival >= 30) {
        return `+${Math.ceil(seconds_arrival / 60)} min`
    }
    else {
        return `-${Math.ceil(Math.abs(seconds_arrival) / 60)} min`
    }
}

const BusStop = ({
    stop, sequence, realtime, realtimeStatus, arrivalRealtime, departureRealtime, isLast, isFirst, color, isPassed, stopRef, gtfsId, delay,
}) => {
    const [timeFormat, setTimeFormat] = useState<FormatType>('relative') // State to manage time format
    const navigate = useNavigate()
    const { t } = useTranslation()
    const theme = useTheme()

    const clickHandler = (stop_id) => {
    // console.log(stop_id);
        navigate({
            to: `/stop/${stop_id}`,
        })
    }

    const toggleTimeFormat = (event) => {
        event.stopPropagation() // This stops the click event from propagating to parent elements
        setTimeFormat(prevFormat => prevFormat === 'relative' ? 'absolute' : 'relative')
    }

    return (
        <Box
            ref={stopRef}
            sx={{
                'display': 'flex',
                'flexDirection': 'row',
                'alignItems': 'center',
                'width': '100%',
                'height': '3rem',
                'position': 'relative',
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: '67%',
                    left: '0.72rem',
                    height: isLast ? '0%' : '66%',
                    width: '8px',
                    bgcolor: !isPassed ? color : 'lightgrey',
                    zIndex: -1,
                },
            }}
        >
            <ListItemIcon
                sx={{
                    minWidth: 'auto',
                    justifyContent: 'center',
                    height: '2rem',
                    position: 'relative',
                    zIndex: 1,
                }}
            >
                {(isFirst || isLast) && <FiberManualRecordIcon sx={{ fontSize: '2rem', color: !isPassed ? color : 'lightgrey' }} />}
                {!(isFirst || isLast) && <FiberManualRecordOutlinedIcon sx={{ fontSize: '2rem', color: !isPassed ? color : 'lightgrey' }} />}
            </ListItemIcon>
            <ListItemButton
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: (isFirst || isLast) ? '4rem' : '3rem',
                    paddingTop: 0,
                    paddingBottom: 0,
                }}
                onClick={() => clickHandler(gtfsId)}
            >
                <ListItemText
                    primary={<Typography sx={{ fontWeight: 'bold', opacity: isPassed ? 0.3 : 1 }}>{stop.name}</Typography>}
                />
                <ListItemText
                    sx={{ textAlign: 'right', opacity: isPassed ? 0.3 : 1, color: realtimeStatus === 'CANCELED' ? 'red' : realtimeStatus === 'UPDATED' ? theme.palette.success.main : theme.palette.text.primary }}
                    primary={(
                        <>
                            {realtimeStatus === 'CANCELED' && (
                                <ErrorOutlineIcon sx={{ color: 'red', marginRight: '5px', fontSize: '1rem' }} />
                            )}
                            {realtimeStatus === 'UPDATED' && (
                                <SensorsIcon sx={{ marginRight: '5px', fontSize: '1rem', animation: 'blink 2s linear infinite', color: theme.palette.success.main }} />
                            )}
                            {realtimeStatus !== 'CANCELED' && realtimeStatus !== 'UPDATED' && (
                                <DepartureBoardIcon sx={{ marginRight: '5px', fontSize: '1rem' }} />
                            )}
                            <span onClick={toggleTimeFormat} style={{ cursor: 'pointer' }}>
                                {formatTime(arrivalRealtime, departureRealtime, timeFormat, t)}
                            </span>
                        </>
                    )}
                    secondary={formatDelay(delay || 0)}
                />
            </ListItemButton>
        </Box>
    )
}

function figureOutIfBusHasPassed(stopTime, tripData) {
    if (stopTime.passed) return true
    const nowSecondsFromMidnight = DateTime.now().toSeconds() - DateTime.now().startOf('day').toSeconds()
    // iterate over all stops, check if there is a stop that has a realtime departure time, if it's only after the current stop, then the bus has passed
    if (stopTime.realtime && stopTime.departure_realtime > nowSecondsFromMidnight) {
        return false
    }
    else {
        // if the any of the stops after the current stop has a realtime departure time, then the bus has passed
        const index = tripData.stop_times.findIndex(stop => stop.stop.gtfs_id === stopTime.stop.gtfs_id)
        const stopsAfterCurrent = tripData.stop_times.slice(index + 1)
        const hasPassed = stopsAfterCurrent.some(stop => stop.realtime && stop.departure_realtime < nowSecondsFromMidnight)
        if (!hasPassed) {
            // just do a simple time check
            return stopTime.departure_realtime < nowSecondsFromMidnight
        }
        else {
            return true
        }
    }
}

const BusTracker = ({ tripData }) => {
    const { stop_times, trip_headsign } = tripData
    const arrivalRefs = useRef([])
    const scrollRef = useRef(null)
    arrivalRefs.current = []

    const scrollToFirstArrival = () => {
        if (scrollRef.current) {
            return
        }
        const firstArrivalIndex = stop_times.findIndex(stopTime => figureOutIfBusHasPassed(stopTime, tripData) === false)
        if (firstArrivalIndex !== -1 && firstArrivalIndex !== 0) {
            arrivalRefs.current[firstArrivalIndex > 1 ? firstArrivalIndex - 2 : firstArrivalIndex > 1 ? firstArrivalIndex - 1 : firstArrivalIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
            scrollRef.current = true
        }
        else if (firstArrivalIndex === 0) {
            // do nothing
        }
    }

    useEffect(() => {
        scrollToFirstArrival()
    }, [tripData]) // run effect when tripData changes

    return (
        <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            {stop_times.map((stopTime, index) => (
                <BusStop
                    key={stopTime.stop.gtfs_id}
                    gtfsId={stopTime.stop.gtfs_id}
                    stop={stopTime.stop}
                    sequence={stopTime.sequence}
                    realtime={stopTime.realtime}
                    realtimeStatus={stopTime.realtime_status}
                    arrivalRealtime={stopTime.arrival_realtime}
                    departureRealtime={stopTime.departure_realtime}
                    delay={stopTime.arrival_delay}
                    color={tripData.color}
                    isFirst={index === 0}
                    isLast={index === stop_times.length - 1}
                    isPassed={figureOutIfBusHasPassed(stopTime, tripData)}
                    stopRef={el => arrivalRefs.current[index] = el} // Assign the ref
                />
            ))}
        </List>
    )
}

export default BusTracker
