import { TFunction } from 'i18next'
import { DateTime } from 'luxon'
import { DataDrivenPropertyValueSpecification, ExpressionSpecification, LngLatBounds } from 'maplibre-gl'
import { useEffect, useState } from 'react'
import { Leg, LineStringModel } from 'src/generated/brezavta/requests/types.gen'

export type FormatType = 'absolute' | 'relative'

function formatAbsoluteSeconds(seconds_today: number): string {
    return DateTime.local({ zone: 'Europe/Ljubljana' }).set({ hour: 0, minute: 0, second: 0 }).plus({ seconds: seconds_today }).toFormat('HH:mm')
}

function getSecondsInDay(dt: DateTime): number {
    return parseInt(dt.toFormat('HH')) * 60 * 60 + parseInt(dt.toFormat('mm')) * 60 + parseInt(dt.toFormat('ss'))
}

/**
 * Formats the time based on the given parameters.
 *
 * If the time is far in the future (more than an hour), the time is formatted as an absolute time.
 * If the time is in the past, the time is formatted as 'Arrived' or 'Departed'.
 * If the time is under a minute, the time is formatted as 'Arriving'.
 *
 * @param {number} seconds_arrival - The arrival time in seconds.
 * @param {number} seconds_departure - The departure time in seconds.
 * @param {FormatType} format - The format type, either 'absolute' or another format.
 * @param {TFunction} t - The translation function.
 * @param {"final" | "first"} [type] - Optional parameter to specify if the time is for the final or first event.
 *
 * @returns {string} - The formatted time string.
 */
export function formatTime(seconds_arrival: number, seconds_departure: number, format: FormatType, t: TFunction, type?: 'final' | 'first') {
    if (format === 'absolute')
        return formatAbsoluteSeconds(seconds_arrival)

    const now = getSecondsInDay(DateTime.local({ zone: 'Europe/Ljubljana' }))
    const timeUntilArrival = seconds_arrival - now
    // Times far in the future should be absolute
    if (timeUntilArrival > 3600) {
        return formatAbsoluteSeconds(seconds_arrival)
    }

    // Currently in station
    if (now > seconds_arrival && now < seconds_departure) {
        return t('At station')
    }

    // Already departed
    if (now > seconds_departure) {
        return type == 'final' ? t('Arrived') : t('Departed')
    }

    // Under a minute
    if (timeUntilArrival < 60) {
        return type == 'first' ? `${timeUntilArrival} s` : t('Arriving')
    }

    return `${Math.floor(timeUntilArrival / 60)} min`
}

export function getBoundsForPolyline(polyline: LineStringModel) {
    return polyline.coordinates.reduce(function (bounds, coord) {
        return bounds.extend(coord)
    }, new LngLatBounds(polyline.coordinates[0], polyline.coordinates[0]))
}

// Function to add random error to coordinates
export function addRandomError(coord) {
    const randomFactor = 0.00007 // Adjust this value to set the amount of error
    return coord + (Math.random() - 0.5) * randomFactor
}

export function useSimpleCache<T>(data: T, localStorageKey: string, default_val: T): T {
    const [stops, setStops] = useState(default_val)

    useEffect(() => {
        const storedStops = localStorage.getItem(localStorageKey)
        if (storedStops) {
            setStops(JSON.parse(storedStops))
        }
    }, [])

    useEffect(() => {
        if (data) {
            setStops(data)
            localStorage.setItem(localStorageKey, JSON.stringify(data))
        }
    }, [data])
    return stops
}

/**
 * Mouse pointer
 * @param map Maplibre instance
 * @param layerId Layer ID
 */
export function bindCursorPointer(map, layerId) {
    map.current.on('mouseenter', layerId, () => {
        map.current.getCanvas().style.cursor = 'pointer'
    })

    map.current.on('mouseleave', layerId, () => {
        map.current.getCanvas().style.cursor = ''
    })
}

export function namespaceIconExpression(expression: ExpressionSpecification): DataDrivenPropertyValueSpecification<string> {
    return ['concat', 'ojpp:', expression]
}

export function calculateBoundsForLegs(legs: Leg[]) {
    const bounds = [[180, 90], [-180, -90]]
    legs.forEach((leg) => {
        leg.geometry.coordinates.forEach((coord) => {
            bounds[0][0] = Math.min(bounds[0][0], coord[0])
            bounds[0][1] = Math.min(bounds[0][1], coord[1])
            bounds[1][0] = Math.max(bounds[1][0], coord[0])
            bounds[1][1] = Math.max(bounds[1][1], coord[1])
        })
    })
    return bounds
}

export const createPulsingDot = (map: maplibregl.Map) => {
    const size = 250
    return {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),
        onAdd: function () {
            const canvas = document.createElement('canvas')
            canvas.width = this.width
            canvas.height = this.height
            this.context = canvas.getContext('2d')
        },
        render: function () {
            const duration = 1000
            const t = (performance.now() % duration) / duration
            const radius = (size / 2) * 0.3
            const outerRadius = (size / 2) * 0.7 * t + radius
            const context = this.context

            context.clearRect(0, 0, this.width, this.height)
            context.beginPath()
            context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2)
            context.fillStyle = `rgba(255, 200, 200, ${1 - t})` // Default light red color
            context.fill()

            context.beginPath()
            context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2)
            context.fillStyle = 'white' // Default darker red color
            context.strokeStyle = 'white'
            context.lineWidth = 2 + 4 * (1 - t)
            context.fill()
            context.stroke()

            this.data = context.getImageData(0, 0, this.width, this.height).data
            map.triggerRepaint()
            return true
        },
    }
}

export function useLayerData(layer: string, data?: object, map, mapLoaded) {
    useEffect(() => {
        if (!mapLoaded || !data) return

        map!.current!.getSource(layer)!.setData(data)

        // Clear the map when leaving this screen
        return () => {
            if (map!.current!.getSource(layer))
                map!.current!.getSource(layer)!.setData({
                    type: 'FeatureCollection',
                    features: [],
                })
        }
    }, [map, mapLoaded, data])
}

export function formatMillisTime(time) {
    return DateTime.fromMillis(time).setZone('Europe/Ljubljana').toFormat('HH:mm')
}

export function decimalFormat(value: number) {
    return value.toFixed(2).replace(".", ",")
}

export function imgProxy(url: string, size: number = 128) {
    return `http://img.tomato.derp.si/insecure/s:${size}/plain/${url}`
}
