import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shareCurrentUrl, isShareSupported as shouldShowShare } from 'src/lib/shareUtil'

const SheetHeader = ({ children, heading, image, menuItems, share }: { children?, heading, image?, menuItems?, share?: boolean }) => {
    const menu = makeMenu(menuItems || [], share)

    return (
        <>
            <Box sx={{ p: 1, paddingLeft: 2 }} style={{ paddingBottom: '10px' }}>
                {...menu}

                <Stack direction="row" spacing={1}>
                    <Typography variant="h6">{heading}</Typography>
                </Stack>

                {children}
            </Box>
        </>
    )
}

function makeMenu(menuItems: MenuItem[], share?) {
    const { t } = useTranslation()
    if (share === undefined) share = true

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    // Add "share this" menu item for app and PWA where URL bar is not available
    if (shouldShowShare() && share)
        menuItems = [(
            <MenuItem key="share" onClick={shareCurrentUrl}>
                {t('Share link')}
            </MenuItem>
        ), ...menuItems]

    // Don't show menu if there are no items
    if (menuItems.length == 0)
        return []

    return [
        (
            <IconButton
                key="more"
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                style={{
                    float: 'right',
                    marginTop: '-5px',
                }}
            >
                <MoreVertIcon />
            </IconButton>
        ),
        (
            <Menu
                key="menu"
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {...menuItems}
            </Menu>
        )]
}

export default SheetHeader
