import Battery4BarIcon from '@mui/icons-material/Battery4Bar'
import LocalParking from '@mui/icons-material/LocalParking'
import PedalBikeIcon from '@mui/icons-material/PedalBike'
import {
    Box,
    CircularProgress,
    List,
    ListItem,
    Stack,
    Typography,
} from '@mui/material'
import { createFileRoute } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import AutomaticSheet from 'src/components/sheet/AutomaticSheet'
import SheetHeader from 'src/components/sheet/SheetHeader'
import { useGetMicromobilityDetailMicromobilityMicromobilityIdGet } from 'src/generated/brezavta/queries'
import { decimalFormat, imgProxy } from 'src/lib/util'

export const Route = createFileRoute('/micromobility/$stopId')({
    component: MicromobilityStop,
})

function MicromobilityStop() {
    const { stopId } = Route.useParams()
    const { t } = useTranslation()

    const { data, isLoading } = useGetMicromobilityDetailMicromobilityMicromobilityIdGet({
        path: {
            micromobility_id: stopId,
        },
    })

    if (isLoading) {
        return (
            <AutomaticSheet>
                <Box sx={{ display: 'flex', height: window.innerHeight / 2, justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            </AutomaticSheet>
        )
    }

    const vehicles = data?.vehicle_list

    return (
        <AutomaticSheet>
            <SheetHeader heading={data?.name}>
                <Typography fontSize={20}>
                    <Stack gap={2} direction="row">
                        <LocalParking />
                        {data?.spaces}
                        <PedalBikeIcon />
                        {data?.vehicles}
                    </Stack>
                </Typography>
            </SheetHeader>

            {vehicles !== undefined && vehicles.length == 0 && (
                t('The station is empty')
            )}
            <List>
                {(vehicles || []).map((item, index) => {
                    const priceLine = item.price_start ? `Start: ${item.price_start} €` : `Min: ${item.price_minimum} €`
                    return (
                        <ListItem key={index + 'l'} style={{ display: 'flex', gap: 5 }}>
                            <img
                                src={imgProxy(item.image_url)}
                                width="70px"
                                style={{ objectFit: 'contain' }}
                            />
                            <Stack direction="column" spacing={1} style={{ flexGrow: 1 }}>
                                {item.model}
                                <Stack direction="column" style={{ fontSize: '.8em' }}>
                                    <Stack direction="row" gap={1}>
                                        <span>
                                            {decimalFormat(item.price_per_km)}
                                            &nbsp;€/km
                                        </span>
                                        <span>
                                            {decimalFormat(item.price_per_min)}
                                            &nbsp;€/min
                                        </span>
                                        <span>
                                            {priceLine}
                                        </span>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Battery4BarIcon />
                            <Stack direction="column" style={{ fontSize: '.8em' }}>
                                <Stack direction="row">
                                    {item.battery}
                                    &nbsp;%
                                </Stack>
                                <Stack direction="row">
                                    {item.range}
                                    &nbsp;km
                                </Stack>
                            </Stack>
                        </ListItem>
                    )
                })}
            </List>
        </AutomaticSheet >
    )
}

export default MicromobilityStop
