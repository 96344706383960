import { ReactNode } from '@tanstack/react-router'
import maplibregl from 'maplibre-gl'
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useRef, useState } from 'react'

export const MapRenderContext = createContext<{
    map: React.MutableRefObject<maplibregl.Map | null> | null
    mapRef: React.RefObject<HTMLDivElement> | null
    mapLoaded: boolean
    setMapLoaded: Dispatch<SetStateAction<boolean>>
}>({
    map: null,
    mapRef: null,
    mapLoaded: false,
    setMapLoaded: null,
})

export const MapRenderProvider: React.FC<PropsWithChildren> = ({ children }: { children: ReactNode }) => {
    const map = useRef<maplibregl.Map | null>(null)
    const mapRef = useRef<HTMLDivElement>(null)
    const [mapLoaded, setMapLoaded] = useState(false)

    return (
        <MapRenderContext.Provider
            value={{
                map,
                mapRef,
                mapLoaded,
                setMapLoaded,
            }}
        >
            {children}
        </MapRenderContext.Provider>
    )
}
