import { Box, Link, Stack, Typography, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import { ReactNode, useRef } from 'react'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import SensorsIcon from '@mui/icons-material/Sensors'
import fontColorContrast from 'font-color-contrast'
import { Leg, Point } from 'src/generated/brezavta/requests/types.gen'
import { getColorForMode, getForegroundColorForMode, getIconForMode } from 'src/lib/modes'
import { formatMillisTime } from 'src/lib/util'

/**
 * One leg in the itinerary view (consists of 3 rows - starting stop, inner details, ending stop)
 */
function ItineraryLeg({ type, leg, nextLeg, previousLeg, isFirst, isLast }: { type: string, leg: Leg, nextLeg?: Leg, previousLeg?: Leg, isFirst?: boolean, isLast?: boolean }) {
    const color = getColorForMode(leg)
    const circleColor = (previousLeg && previousLeg.mode !== leg.mode && leg.mode === 'WALK')
        ? getColorForMode(previousLeg)
        : getColorForMode(leg)
    console.log(color, circleColor)
    // If the end of this leg is the same as the start of the next leg, we don't need to render it
    const lastMatchNext = leg?.to_point.arrival_time == nextLeg?.from_point?.departure_time && leg?.to_point.vertex_type == nextLeg?.from_point?.vertex_type
    return (
        <>
            <ItineraryRow time={formatMillisTime(leg.from_point.departure_time)} color={color} circle={true} isFirst={isFirst} circleColor={circleColor}>
                <ItineraryLegPoint leg={leg} point={leg.from_point} />
            </ItineraryRow>

            <ItineraryRow color={color}>
                <ItineraryLegTripDetails leg={leg} visible={true} />
            </ItineraryRow>
            {
                (!lastMatchNext) && (
                    <ItineraryRow time={formatMillisTime(leg.to_point.departure_time)} color="lightgrey" circle={true} isLast={isLast} circleColor={isLast || (nextLeg && nextLeg.mode !== 'WALK' && leg.mode === 'WALK') ? 'lightgrey' : circleColor}>
                        <ItineraryLegPoint leg={leg} point={leg.to_point} />
                    </ItineraryRow>
                )
            }
        </>
    )
}

/**
 * Generic row in the itinerary view (the thing that makes the line with circles)
 */
const ItineraryRow = ({ time, color, circle, circleColor, isLast, children, isFirst }: { time?: string, color: string, circle: boolean, circleColor: string, isLast?: boolean, children: ReactNode, isFirst?: boolean }) => {
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '3rem', padding: '.5rem', textAlign: 'center' }}>
                <Typography variant="body2" sx={{ padding: 0, margin: 0}}>
                    {time}
                </Typography>
            </div>
            <div style={{ position: 'relative' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: (isFirst && '50%') || (isLast && '-50%') || '0%',
                        left: '50%',
                        transform: 'translate(-50%, 6px)',
                        width: '8px',
                        bgcolor: color,
                        height: '100%',
                    }}
                />
                <ItineraryCircle color={circleColor} size={22} style={{ marginTop: '6px', position: 'relative', zIndex: 1, visibility: circle ? 'visible' : 'hidden' }} />
            </div>

            <div style={{ flex: 1, padding: '.5rem' }}>
                {children}
            </div>
        </div>
    )
}

/**
 * The "point" row of an itinerary Trip (starting or ending point)
 */
const ItineraryLegPoint = ({ leg, point }: { leg: Leg, point: Point }) => {
    const navigate = useNavigate()
    // Micromobility station
    if (leg.is_rental) {
        return (
            <Link
                onClick={() => {
                    navigate({
                        to: '/micromobility/$stopId',
                        params: {
                            stopId: point.id,
                        },

                    })
                }}
                sx={{ textDecoration: 'none', color: 'inherit', width: '100%', cursor: 'pointer' }}
            >
                <div style={{ width: 'calc(100% - 2rem)', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="body1" sx={{ textWrap: 'wrap', textAlign: 'left', verticalAlign: 'super' }}>
                        {`${point.name}`}
                    </Typography>
                    <NavigateNextIcon sx={{ fontSize: 'small', color: 'primary.main', verticalAlign: 'middle' }} />
                </div>
            </Link>
        )
    }

    return (
        <Typography variant="body1">
            {point.name}
        </Typography>
    )
}

/**
 * The "inner" part of a leg in the itinerary view
 */
function ItineraryLegTripDetails({ leg, visible }: { leg: Leg, visible: boolean }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const commonStyles = {
        paddingTop: '3px',
        paddingBottom: '3px',
        paddingRight: '3px',
        borderRadius: '5px',
        textWrap: 'no-wrap',
        height: '1.5rem',
    }

    const typographyStyles = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }

    const backgroundColor = getColorForMode(leg, 'pill')
    const textColor = getForegroundColorForMode(leg) || fontColorContrast(backgroundColor)
    const icon = getIconForMode(leg)

    let text = null as any
    let additionalText = null as any
    let link = null as any
    let additionalData = null

    if (leg.mode === 'WALK') {
        text = `${t('Walk for')} ${calculateLegLength(leg).replace('.', ',')} (${endStartDiffToMinutes(leg.end_time, leg.start_time)} min)`
    }
    else if (leg.mode === 'BUS' || leg.mode === 'RAIL') {
        text = leg.trip.short_name

        additionalText = (
            <>
                <Typography variant="body1" sx={{ textWrap: 'wrap' }}>{leg.trip.headsign}</Typography>
                <Typography variant="body1" sx={{ textWrap: 'wrap' }}>
                    {leg.trip.route_long_name}
                    {' '}
                    {leg.realtime ? <SensorsIcon sx={{ color: 'success.main', animation: 'blink 2s linear infinite' }} /> : <></>}
                </Typography>
            </>
        )

        link
            = (
                <NavigateNextIcon
                    sx={{ color: 'primary.main', marginLeft: 'auto', cursor: 'pointer', marginRight: '1rem' }}
                    onClick={() => {
                        navigate({
                            to: `/trip/${leg.trip.gtfs_id}`,
                        })
                    }}
                />
            )

        additionalData
            = (
                <ItineraryLegIntermediateStops
                    leg={leg}
                    onClick={(stop) => {
                        navigate({
                            to: `/stop/${stop.gtfs_id}`,
                        })
                    }}
                />
            )
    }
    else if (leg.mode === 'BICYCLE') {
        text = `${t('Cycle for')} ${calculateLegLength(leg).replace('.', ',')} (${endStartDiffToMinutes(leg.end_time, leg.start_time)} min)`
    }
    else {
        text = endStartDiffToMinutes(leg.end_time, leg.start_time) + ' min'
    }
    console.log(text, additionalText)
    return (
        <div style={{ width: '100%', visibility: visible ? 'visible' : 'hidden'}}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Stack direction="row" spacing={1} sx={{ textAlign: 'left', display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Stack direction="row" spacing={1} sx={{ backgroundColor, color: textColor, ...commonStyles }}>
                        {icon}
                        <Typography variant="body1" sx={typographyStyles}>{text}</Typography>
                    </Stack>
                    {additionalText}
                </Stack>
                {link}
            </div>
            {
                additionalData && (
                    <Stack direction="row" spacing={1} sx={{ textAlign: 'left', display: 'flex', alignItems: 'center', width: '100%', marginTop: '1rem' }}>
                        {additionalData}
                    </Stack>
                )
            }
        </div>
    )
}

/**
 * Shows intermediate stops in TRANSIT mode Legs of an Itinerary
 */
const ItineraryLegIntermediateStops = ({ leg, onClick }: { leg: Leg, onClick: Function }) => {
    const { t } = useTranslation()

    if (leg.intermediate_stops === null) return // Makes TypeScript hapy

    const accordionRef = useRef(null)
    const intermediate_stops = leg.intermediate_stops.length == 0 ? `${t('No intermediate stops')}` : `${t('number_stops', { count: leg.intermediate_stops.length })}`

    return (
        <Accordion sx={{ width: '100%', marginTop: '1rem', boxShadow: 'none' }} ref={accordionRef}>
            <AccordionSummary sx={{ width: '100%' }} expandIcon={leg.intermediate_stops.length > 0 ? <ExpandMoreIcon sx={{ color: 'primary.main' }} /> : null}>
                <Typography variant="body1" sx={{ textWrap: 'wrap', color: 'primary.main' }}>{`${intermediate_stops} (${endStartDiffToMinutes(leg.end_time, leg.start_time)} min)`}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ width: '100%', padding: 0 }}>
                <ul style={{ listStyleType: 'none', padding: 0, marginLeft: 'calc(-62px - 6px)' }}>
                    {leg.intermediate_stops.map((stop, index) => (
                        <li key={index} style={{ paddingBottom: '.5rem' }}>
                            <Stack direction="row" spacing={4} alignItems="center">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="caption" sx={{ textWrap: 'wrap', textAlign: 'left', verticalAlign: 'super' }}>
                                            {`${DateTime.fromMillis(stop.departure_time).setZone('Europe/Ljubljana').toFormat('HH:mm')}`}
                                        </Typography>
                                    </div>
                                    <ItineraryCircle size={14} color={leg.trip.color} style={{ marginLeft: '10px' }} />
                                </div>
                                <Link
                                    onClick={() => {
                                        onClick(stop)
                                    }}
                                    sx={{ textDecoration: 'none', color: 'inherit', width: '100%', cursor: 'pointer' }}
                                >
                                    <div style={{ width: 'calc(100% - 2rem)', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="body1" sx={{ textWrap: 'wrap', textAlign: 'left', verticalAlign: 'super' }}>
                                            {`${stop.name}`}
                                        </Typography>
                                        <NavigateNextIcon sx={{ fontSize: 'small', color: 'primary.main', verticalAlign: 'middle' }} />
                                    </div>
                                </Link>
                            </Stack>
                        </li>
                    ))}
                </ul>

            </AccordionDetails>
        </Accordion>
    )
}

const ItineraryCircle = ({ color, size, style }) => {
    const theme = useTheme()
    size = size || 32

    return (
        <span style={{
            display: 'inline-block',
            width: size,
            height: size,
            borderRadius: '50%',
            borderStyle: 'solid',
            borderWidth: size / 5,
            borderColor: color,
            backgroundColor: theme.palette.background.default,
            ...style,
        }}
        />
    )
}

function endStartDiffToMinutes(end, start) {
    return Math.ceil((end - start) / 60000)
}

function calculateLegLength(leg) {
    // flatten the distances in the steps
    const steps = leg.steps
    let length = 0
    steps.forEach((step) => {
        length += step.distance
    })
    return length < 1000 ? parseInt(length) + ' m' : (length / 1000).toFixed(1) + ' km'
}

export default ItineraryLeg
