import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import ElectricScooterIcon from '@mui/icons-material/ElectricScooter'
import TrainIcon from '@mui/icons-material/Train'
import { Typography } from '@mui/material'
import { Leg } from 'src/generated/brezavta/requests/types.gen'

export function getColorForMode(leg: Leg, type?: 'pill' | undefined) {
    switch (leg.mode) {
        case 'WALK':
            return type == 'pill' ? 'white' : 'lightgrey'
        case 'BUS':
        case 'RAIL':
            return leg.trip?.color
        case 'BICYCLE':
            return type == 'pill' ? 'white' : 'green'
        case 'CAR':
            return 'blue'
        default:
            return 'red'
    }
}

export function getForegroundColorForMode(leg: Leg) {
    switch (leg.mode) {
        case 'BUS':
        case 'RAIL':
            return leg.trip?.text_color
    }
}

export function getIconForMode(leg, sx = undefined) {
    switch (leg.mode) {
        case 'WALK':
            return <DirectionsWalkIcon sx={sx} />
        case 'BUS':
            return <DirectionsBusIcon sx={sx} />
        case 'RAIL':
            return <TrainIcon sx={sx} />
        case 'BICYCLE':
            return <DirectionsBikeIcon sx={sx} />
        case 'CAR':
            return <DirectionsCarIcon sx={sx} />
        case 'SCOOTER':
            return <ElectricScooterIcon sx={sx} />
        default:
            return (
                <Typography
                    variant="body1"
                    sx={{
                        overflow: 'hidden',
                    }}
                >
                    {' '}
                    ?
                    {' '}
                </Typography>
            )
    }
}
