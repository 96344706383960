import { Box, useTheme } from '@mui/material'
import { useNavigate } from '@tanstack/react-router'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { MapRenderContext } from 'src/contexts/MapRenderContext'

const BottomSheetPadded = ({ children }) => {
    const navigate = useNavigate()
    const { map, mapRef } = useContext(MapRenderContext)
    const theme = useTheme()

    const bottomSheetRef = useRef<Element>()
    const pullerRef = useRef<Element>()

    // RESIZING SHIT

    const [height, setHeight] = useState(50)
    const lastY = useRef<number | null>(null)

    function resize(e: MouseEvent | TouchEvent) {
        const clientY = e instanceof MouseEvent ? e.clientY : e.touches[0].clientY
        if (!lastY.current) {
            lastY.current = clientY
            return
        }

        const deltaPx = clientY - lastY.current!
        lastY.current = clientY

        // Set new height based on the current height
        setHeight((currentHeightPecent) => {
            const deltaPercent = (deltaPx / window.innerHeight) * 100
            let nh = currentHeightPecent - deltaPercent
            // When under 10 %, snap to 0
            if (nh < 10)
                nh = 0
            return Math.min(100, nh)
        })
    }

    useEffect(() => {
        mapRef!.current!.style.height = bottomSheetRef.current.offsetTop + 'px'
        map!.current!.resize()
        return () => {
            mapRef!.current!.style.height = '100%'
            map!.current!.resize()
        }
    }, [map, mapRef, bottomSheetRef, height])

    // Dismissed by swiping all the way down
    useEffect(() => {
        if (height == 0) {
            cleanUpMap()
            navigate({
                to: '/',
            })
        }
    }, [height])

    // On open set map height accordingly
    useEffect(() => {
        setHeight(50)
        return () => {
            setHeight(0)
        }
    }, [])

    // Bind events

    const enableResize = useCallback(() => {
        document.addEventListener('mousemove', resize)
        document.addEventListener('touchmove', resize)
        // Disabling mouse events on the map makes the resize lag less
        mapRef!.current!.style.pointerEvents = 'none'
    }, [mapRef])

    const disableResize = useCallback(() => {
        lastY.current = null
        document.removeEventListener('mousemove', resize)
        document.removeEventListener('touchmove', resize)
        if (mapRef && mapRef.current)
            mapRef.current.style.pointerEvents = ''
    }, [mapRef])

    useEffect(() => {
        pullerRef.current?.addEventListener('mousedown', enableResize)
        pullerRef.current?.addEventListener('touchstart', enableResize)
        document.addEventListener('mouseup', disableResize)
        document.addEventListener('touchend', disableResize)
        return () => {
            disableResize()
            pullerRef.current?.removeEventListener('mousedown', disableResize)
            pullerRef.current?.removeEventListener('touchstart', disableResize)
            document.removeEventListener('mouseup', disableResize)
            document.removeEventListener('touchend', disableResize)
        }
    }, [])

    // When drawer opens, clean up the map
    // TODO: move this somewhere more sensible or just manage map state properly

    const cleanUpMap = () => {
        if (!map || !map.current || !map.current.getStyle()) return
        const cleanable_layers = ['trip-polyline', 'vehicles']
        map?.current.getStyle().layers.forEach((layer) => {
            // console.log('layer', layer.id);
            if (cleanable_layers.includes(layer.id)) {
                // console.log('removing layer', layer.id);
                map?.current!.removeLayer(layer.id)
                // remove source with the same id
                map?.current!.removeSource(layer.id)
            }
        })
        const cleanable_sources = ['planView', 'planVehicleLocations', 'tripPolyline', 'vehicles']
        cleanable_sources.forEach((source) => {
            if (map?.current.getSource(source)) {
                console.log('cleaning source:', source)
                map?.current.getSource(source).setData({
                    type: 'FeatureCollection',
                    features: [],
                })
            }
            else {
                console.log('source not found:', source)
            }
        })
    }

    return (
        <div
            ref={bottomSheetRef}
            style={{
                position: 'absolute',
                bottom: 56, // Offset for bottom nav height
                width: '100%',
                height: 'calc(' + height + '%)',
                background: theme.palette.background.default,
                zIndex: 3,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                ref={pullerRef}
                style={{
                    'width': '100%',
                    'boxSizing': 'border-box',
                    'padding': 10,
                    'zIndex': 1100,
                    'backgroundColor': theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)',
                    '&:hover': {
                        backgroundColor: theme.palette.mode === 'light' ? '#b0b0b0' : '#555',
                    },
                }}
            >
                <div style={{
                    width: '10vw',
                    height: 6,
                    borderRadius: 3,
                    margin: '0 auto',
                    // position: 'absolute',
                    // top: theme.spacing(1),
                    // left: 'calc(50% - 5vw)',
                    cursor: 'ns-resize',
                    zIndex: 1200,
                    backgroundColor: theme.palette.mode === 'light' ? '#ccc' : '#444',

                }}
                >
                </div>
            </div>
            <Box sx={{ py: 2, overflowY: 'auto' }}>
                {children}
            </Box>
        </div>
    )
}

export default BottomSheetPadded
