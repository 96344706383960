import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'

export const LineChip = ({ text, color, textColor, extraStyles }: { text: string, textColor: string, extraStyles: object }) => (
    <Chip
        label={<Typography component="span" variant="h6">{text}</Typography>}
        style={{
            color: textColor,
            backgroundColor: color,
            paddingLeft: 1,
            ...extraStyles,
        }}
    />
)
