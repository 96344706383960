// generated with @7nohe/openapi-react-query-codegen@2.0.0-beta.3 

import { type Options } from "@hey-api/client-fetch";
import { UseQueryResult } from "@tanstack/react-query";
import { getMicromobilityDetailMicromobilityMicromobilityIdGet, getMicromobilityMicromobilityGet, getParkingParkingGet, getPlanPlanTransportModesFromLonLatToLonLatGet, getStopStopsStopIdGet, getTripGeometryTripsTripIdGeometryGet, getTripTripsTripIdGet, getTripWithGeometryTripsTripIdUiGet, getVehicleLocationsTripsTripIdVehiclesGet, listArrivalsStopsStopIdArrivalsGet, listScheduleStopsStopIdScheduleDateGet, listStopsStopsGet, listVehiclesVehiclesLocationsGet, reverseGeocodeGeocodingReverseGeocodeGet, robotsRobotsTxtGet, searchGeocodingSearchGet } from "../requests/services.gen";
export type GetParkingParkingGetDefaultResponse = Awaited<ReturnType<typeof getParkingParkingGet>>["data"];
export type GetParkingParkingGetQueryResult<TData = GetParkingParkingGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useGetParkingParkingGetKey = "GetParkingParkingGet";
export const UseGetParkingParkingGetKeyFn = (clientOptions: Options<unknown, true> = {}, queryKey?: Array<unknown>) => [useGetParkingParkingGetKey, ...(queryKey ?? [clientOptions])];
export type GetMicromobilityMicromobilityGetDefaultResponse = Awaited<ReturnType<typeof getMicromobilityMicromobilityGet>>["data"];
export type GetMicromobilityMicromobilityGetQueryResult<TData = GetMicromobilityMicromobilityGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useGetMicromobilityMicromobilityGetKey = "GetMicromobilityMicromobilityGet";
export const UseGetMicromobilityMicromobilityGetKeyFn = (clientOptions: Options<unknown, true> = {}, queryKey?: Array<unknown>) => [useGetMicromobilityMicromobilityGetKey, ...(queryKey ?? [clientOptions])];
export type GetMicromobilityDetailMicromobilityMicromobilityIdGetDefaultResponse = Awaited<ReturnType<typeof getMicromobilityDetailMicromobilityMicromobilityIdGet>>["data"];
export type GetMicromobilityDetailMicromobilityMicromobilityIdGetQueryResult<TData = GetMicromobilityDetailMicromobilityMicromobilityIdGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useGetMicromobilityDetailMicromobilityMicromobilityIdGetKey = "GetMicromobilityDetailMicromobilityMicromobilityIdGet";
export const UseGetMicromobilityDetailMicromobilityMicromobilityIdGetKeyFn = (clientOptions: Options<unknown, true>, queryKey?: Array<unknown>) => [useGetMicromobilityDetailMicromobilityMicromobilityIdGetKey, ...(queryKey ?? [clientOptions])];
export type GetTripTripsTripIdGetDefaultResponse = Awaited<ReturnType<typeof getTripTripsTripIdGet>>["data"];
export type GetTripTripsTripIdGetQueryResult<TData = GetTripTripsTripIdGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useGetTripTripsTripIdGetKey = "GetTripTripsTripIdGet";
export const UseGetTripTripsTripIdGetKeyFn = (clientOptions: Options<unknown, true>, queryKey?: Array<unknown>) => [useGetTripTripsTripIdGetKey, ...(queryKey ?? [clientOptions])];
export type GetTripGeometryTripsTripIdGeometryGetDefaultResponse = Awaited<ReturnType<typeof getTripGeometryTripsTripIdGeometryGet>>["data"];
export type GetTripGeometryTripsTripIdGeometryGetQueryResult<TData = GetTripGeometryTripsTripIdGeometryGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useGetTripGeometryTripsTripIdGeometryGetKey = "GetTripGeometryTripsTripIdGeometryGet";
export const UseGetTripGeometryTripsTripIdGeometryGetKeyFn = (clientOptions: Options<unknown, true>, queryKey?: Array<unknown>) => [useGetTripGeometryTripsTripIdGeometryGetKey, ...(queryKey ?? [clientOptions])];
export type GetTripWithGeometryTripsTripIdUiGetDefaultResponse = Awaited<ReturnType<typeof getTripWithGeometryTripsTripIdUiGet>>["data"];
export type GetTripWithGeometryTripsTripIdUiGetQueryResult<TData = GetTripWithGeometryTripsTripIdUiGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useGetTripWithGeometryTripsTripIdUiGetKey = "GetTripWithGeometryTripsTripIdUiGet";
export const UseGetTripWithGeometryTripsTripIdUiGetKeyFn = (clientOptions: Options<unknown, true>, queryKey?: Array<unknown>) => [useGetTripWithGeometryTripsTripIdUiGetKey, ...(queryKey ?? [clientOptions])];
export type GetVehicleLocationsTripsTripIdVehiclesGetDefaultResponse = Awaited<ReturnType<typeof getVehicleLocationsTripsTripIdVehiclesGet>>["data"];
export type GetVehicleLocationsTripsTripIdVehiclesGetQueryResult<TData = GetVehicleLocationsTripsTripIdVehiclesGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useGetVehicleLocationsTripsTripIdVehiclesGetKey = "GetVehicleLocationsTripsTripIdVehiclesGet";
export const UseGetVehicleLocationsTripsTripIdVehiclesGetKeyFn = (clientOptions: Options<unknown, true>, queryKey?: Array<unknown>) => [useGetVehicleLocationsTripsTripIdVehiclesGetKey, ...(queryKey ?? [clientOptions])];
export type ListVehiclesVehiclesLocationsGetDefaultResponse = Awaited<ReturnType<typeof listVehiclesVehiclesLocationsGet>>["data"];
export type ListVehiclesVehiclesLocationsGetQueryResult<TData = ListVehiclesVehiclesLocationsGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useListVehiclesVehiclesLocationsGetKey = "ListVehiclesVehiclesLocationsGet";
export const UseListVehiclesVehiclesLocationsGetKeyFn = (clientOptions: Options<unknown, true> = {}, queryKey?: Array<unknown>) => [useListVehiclesVehiclesLocationsGetKey, ...(queryKey ?? [clientOptions])];
export type ListStopsStopsGetDefaultResponse = Awaited<ReturnType<typeof listStopsStopsGet>>["data"];
export type ListStopsStopsGetQueryResult<TData = ListStopsStopsGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useListStopsStopsGetKey = "ListStopsStopsGet";
export const UseListStopsStopsGetKeyFn = (clientOptions: Options<unknown, true> = {}, queryKey?: Array<unknown>) => [useListStopsStopsGetKey, ...(queryKey ?? [clientOptions])];
export type GetStopStopsStopIdGetDefaultResponse = Awaited<ReturnType<typeof getStopStopsStopIdGet>>["data"];
export type GetStopStopsStopIdGetQueryResult<TData = GetStopStopsStopIdGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useGetStopStopsStopIdGetKey = "GetStopStopsStopIdGet";
export const UseGetStopStopsStopIdGetKeyFn = (clientOptions: Options<unknown, true>, queryKey?: Array<unknown>) => [useGetStopStopsStopIdGetKey, ...(queryKey ?? [clientOptions])];
export type ListArrivalsStopsStopIdArrivalsGetDefaultResponse = Awaited<ReturnType<typeof listArrivalsStopsStopIdArrivalsGet>>["data"];
export type ListArrivalsStopsStopIdArrivalsGetQueryResult<TData = ListArrivalsStopsStopIdArrivalsGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useListArrivalsStopsStopIdArrivalsGetKey = "ListArrivalsStopsStopIdArrivalsGet";
export const UseListArrivalsStopsStopIdArrivalsGetKeyFn = (clientOptions: Options<unknown, true>, queryKey?: Array<unknown>) => [useListArrivalsStopsStopIdArrivalsGetKey, ...(queryKey ?? [clientOptions])];
export type ListScheduleStopsStopIdScheduleDateGetDefaultResponse = Awaited<ReturnType<typeof listScheduleStopsStopIdScheduleDateGet>>["data"];
export type ListScheduleStopsStopIdScheduleDateGetQueryResult<TData = ListScheduleStopsStopIdScheduleDateGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useListScheduleStopsStopIdScheduleDateGetKey = "ListScheduleStopsStopIdScheduleDateGet";
export const UseListScheduleStopsStopIdScheduleDateGetKeyFn = (clientOptions: Options<unknown, true>, queryKey?: Array<unknown>) => [useListScheduleStopsStopIdScheduleDateGetKey, ...(queryKey ?? [clientOptions])];
export type SearchGeocodingSearchGetDefaultResponse = Awaited<ReturnType<typeof searchGeocodingSearchGet>>["data"];
export type SearchGeocodingSearchGetQueryResult<TData = SearchGeocodingSearchGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useSearchGeocodingSearchGetKey = "SearchGeocodingSearchGet";
export const UseSearchGeocodingSearchGetKeyFn = (clientOptions: Options<unknown, true>, queryKey?: Array<unknown>) => [useSearchGeocodingSearchGetKey, ...(queryKey ?? [clientOptions])];
export type ReverseGeocodeGeocodingReverseGeocodeGetDefaultResponse = Awaited<ReturnType<typeof reverseGeocodeGeocodingReverseGeocodeGet>>["data"];
export type ReverseGeocodeGeocodingReverseGeocodeGetQueryResult<TData = ReverseGeocodeGeocodingReverseGeocodeGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useReverseGeocodeGeocodingReverseGeocodeGetKey = "ReverseGeocodeGeocodingReverseGeocodeGet";
export const UseReverseGeocodeGeocodingReverseGeocodeGetKeyFn = (clientOptions: Options<unknown, true>, queryKey?: Array<unknown>) => [useReverseGeocodeGeocodingReverseGeocodeGetKey, ...(queryKey ?? [clientOptions])];
export type GetPlanPlanTransportModesFromLonLatToLonLatGetDefaultResponse = Awaited<ReturnType<typeof getPlanPlanTransportModesFromLonLatToLonLatGet>>["data"];
export type GetPlanPlanTransportModesFromLonLatToLonLatGetQueryResult<TData = GetPlanPlanTransportModesFromLonLatToLonLatGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useGetPlanPlanTransportModesFromLonLatToLonLatGetKey = "GetPlanPlanTransportModesFromLonLatToLonLatGet";
export const UseGetPlanPlanTransportModesFromLonLatToLonLatGetKeyFn = (clientOptions: Options<unknown, true>, queryKey?: Array<unknown>) => [useGetPlanPlanTransportModesFromLonLatToLonLatGetKey, ...(queryKey ?? [clientOptions])];
export type RobotsRobotsTxtGetDefaultResponse = Awaited<ReturnType<typeof robotsRobotsTxtGet>>["data"];
export type RobotsRobotsTxtGetQueryResult<TData = RobotsRobotsTxtGetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useRobotsRobotsTxtGetKey = "RobotsRobotsTxtGet";
export const UseRobotsRobotsTxtGetKeyFn = (clientOptions: Options<unknown, true> = {}, queryKey?: Array<unknown>) => [useRobotsRobotsTxtGetKey, ...(queryKey ?? [clientOptions])];
