import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import { client } from './generated/brezavta/requests/services.gen'
import './i18n'

client.setConfig({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    throwOnError: true, // If you want to handle errors on `onError` callback of `useQuery` and `useMutation`, set this to `true`
})

Sentry.init({
    enabled: import.meta.env.PROD,
    dsn: 'https://01d96dd846585c229c61f05e579de2d1@o1099481.ingest.us.sentry.io/4508429312196608',
    integrations: [],
    release: __BREZAVTA_VERSION__,
})

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
)
