import { useNavigate } from '@tanstack/react-router'
import React, { useContext, useEffect } from 'react'
import { MapRenderContext } from 'src/contexts/MapRenderContext'
import { useGetMicromobilityMicromobilityGet } from 'src/generated/brezavta/queries'
import { Micromobility } from 'src/generated/brezavta/requests/types.gen'
import { ICON_BASE_SIZE } from 'src/lib/constants'
import { bindCursorPointer, namespaceIconExpression } from 'src/lib/util'

function getIconName(operator: string, icon: string, detail: boolean = false) {
    operator = operator.replace('_ljubljana', 'ljubljana').replace('_maribor', 'maribor')
    operator = operator.split('_')[0]
    const str = detail && icon != 'SINGLE' ? `${operator}_detail_${icon}` : `${operator}_${icon}`
    return str.toLowerCase()
}

const accepted_providers = [
    // Floating providers should be below stations
    'kvik',
    'sharengo',
    // Station-based systems are next
    'nextbike_cf',
    'nextbike_cn',
    'nextbike_cc',
    'nextbike_ck',
    'nextbike_ce',
    'nextbike_cx',

    'avant2go_si',

    'greengo_544',
    'greengo_603',
    'greengo_1117',
    'greengo_1238',

    'jcdecaux_maribor',
    'jcdecaux_ljubljana',

    'scbikes_gbike',
    'scbikes_pokolo',
    'scbikes_kras',
    'scbikes_sobota',
    'scbikes_gonm',
    'scbikes_posbikes',
    'scbikes_biciklanc',
    'scbikes_pors',
    'scbikes_kulu',
]

const DETAIL_MIN_ZOOM = 14

const MicromobilityLayer: React.FC = () => {
    const { map, mapLoaded } = useContext(MapRenderContext)

    const navigate = useNavigate()

    const { data: micromobilities, refetch } = useGetMicromobilityMicromobilityGet()

    // refetch once every 1 minute
    useEffect(() => {
        const interval = setInterval(() => {
            refetch()
        }, 60000)

        return () => clearInterval(interval)
    }, [refetch])

    const updateAllSources = () => {
        const micromobilitySources = Object.keys(map!.current!.getStyle().sources).filter(source => source.startsWith('micromobilities-'))
        // update sources
        for (const source of micromobilitySources) {
            const network = source.split('-')[1]
            const networkStops = micromobilities.filter(m => m.network === network)

            map!.current!.getSource(source).setData({
                type: 'FeatureCollection',
                features: networkStops.map(m => ({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [m.lon, m.lat],
                    },
                    properties: {
                        id: m.id,
                        icon: getIconName(m.network, m.icon, false),
                        detail_icon: getIconName(m.network, m.icon, true),
                        title: m.name,
                        capacity: m.spaces,
                        available: m.vehicles || '0',
                        floating: m.type === 'FLOATING' ? 1 : 0,
                        network: m.network,
                    },
                })),
            })
        }
    }

    const tryCreateAllSources = () => {
        for (const network of accepted_providers) {
            const sourceId = `micromobilities-${network.toLowerCase()}`
            const source = map!.current!.getSource(sourceId)

            if (source) continue

            const circleLayerId = `micromobilities-circles-${network.toLowerCase()}`
            const symbolLayerId = `micromobilities-symbol-${network.toLowerCase()}`

            // Add empty source for layer
            map!.current!.addSource(sourceId, {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [],
                },
            })

            // Lower layer
            map!.current!.addLayer({
                id: circleLayerId,
                type: 'symbol',
                source: sourceId,
                maxzoom: DETAIL_MIN_ZOOM,
                layout: {
                    'icon-image': namespaceIconExpression(['get', 'icon']),
                    'icon-size': [
                        'interpolate', ['linear'],
                        ['zoom'],
                        // (zoom, size) pairs
                        10, ICON_BASE_SIZE * 0.1,
                        13, ICON_BASE_SIZE * 1,
                    ],
                    'icon-allow-overlap': true,
                    'icon-anchor': 'left',
                },
            })

            // Upper layer
            map!.current!.addLayer({
                id: symbolLayerId,
                type: 'symbol',
                source: sourceId,
                minzoom: DETAIL_MIN_ZOOM,
                layout: {
                    'icon-image': namespaceIconExpression(['get', 'detail_icon']),
                    'icon-size': ICON_BASE_SIZE * 1,
                    'text-field': ['format',
                        ['get', 'available'],
                        { 'text-color': ['case', ['==', ['get', 'available'], '0'], 'red', ['==', ['get', 'available'], 0], 'red', 'green'] },
                        '\n',
                        { 'text-color': 'gray' },
                        ['get', 'capacity'],
                        { 'text-color': 'black' },
                    ],
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                    // "text-size": ["step", ["zoom"], 0, 15, ["case", ["==", ["get", "floating"], 1], 0, 11]],
                    'text-size': [
                        'case',
                        // IF floating
                        ['==', ['get', 'floating'], 1],
                        // => size 0 (invisible)
                        0,
                        // ELSE => size 11
                        11,
                    ],
                    // float text to right of icon
                    'text-offset': [3.5, 0],
                    'icon-allow-overlap': true,
                    'icon-anchor': 'left',
                    'text-allow-overlap': true,
                },
            })

            bindCursorPointer(map, symbolLayerId)

            map!.current!.on('click', symbolLayerId, (e) => {
                const item = e.features![0].properties as Micromobility
                // TODO: item.floating is non-standard, remove this when the backend is fixed
                if (item.type == 'FLOATING' || item.floating == 1) return
                navigate({ to: `/micromobility/${item.id}` })
            })
        }
    }

    useEffect(() => {
        if (!mapLoaded || !micromobilities) return

        tryCreateAllSources()
        updateAllSources()
    }, [mapLoaded, micromobilities])

    return null
}

export default MicromobilityLayer
