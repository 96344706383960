import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
}

const BackButton = () => {
    const theme = useTheme()

    const handleBackClick = () => {
        window.history.back()
    }

    // Only render on iOS devices
    if (!isIOS()) {
        return null
    }

    const styles = {
        backButton: {
            'position': 'fixed',
            'top': 'calc(env(safe-area-inset-top) + 10px)',
            'left': 'calc(env(safe-area-inset-left) + 10px)',
            'zIndex': 1000,
            'backgroundColor': theme.palette.primary.main,
            'color': '#fff',
            'borderRadius': '50%',
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
        icon: {
            color: '#fff',
        },
    }

    return (
        <IconButton
            onClick={handleBackClick}
            sx={styles.backButton}
        >
            <ArrowBackIcon sx={styles.icon} />
        </IconButton>
    )
}

export default BackButton
