import BusAlertIcon from '@mui/icons-material/BusAlert'
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import SensorsIcon from '@mui/icons-material/Sensors'
import { List, ListItem, ListItemButton, ListItemText, Stack, useTheme } from '@mui/material'
import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { Arrival } from 'src/generated/brezavta/requests'
import { LineChip } from './LineChip'

const ArrivalsList = ({ arrivals, formatTime, getNextTime }: { arrivals: Arrival[], formatTime: Function, getNextTime: Function }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const theme = useTheme()


    const clickHandler = (tripId) => {
        navigate({
            to: `/trip/${tripId}`,
        })
    }

    return (
        <List style={{ padding: 0, width: '100%' }}>
            {arrivals && arrivals.length > 0
                ? (
                        arrivals.map((arrival, index) => (
                            <ListItemButton key={index} onClick={() => clickHandler(arrival.trip_id)}>
                                <ListItemText
                                    primary={(
                                        <Stack direction="row" spacing={1}>
                                            <LineChip
                                                text={arrival.route_short_name}
                                                textColor={arrival.route_color_text}
                                                color={arrival.route_color_background}
                                            />
                                            {arrival.alerts.length > 0
                                                ? (
                                                        <BusAlertIcon style={{ color: 'orange', marginRight: '5px', fontSize: '1rem' }} />
                                                    )
                                                : (
                                                        <div style={{ width: '24px' }} />
                                                    )}
                                        </Stack>
                                    )}
                                    secondary={arrival.trip_headsign.toUpperCase() + (arrival.trip_id.includes('IJPP') ? ` | ${arrival.agency_name}` : '')}
                                    style={{ textAlign: 'left' }}
                                />
                                <ListItemText
                                    primary={(
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-end" // Ensures alignment to the right
                                            // vertically center the icon and text
                                            alignItems="center"
                                            sx={{ width: '100%' }} // Full-width container to align text properly
                                        >
                                            {arrival.realtime_status === 'CANCELED'
                                                ? (
                                                        <ErrorOutlineIcon style={{ color: 'red', fontSize: '1rem', marginRight: '5px' }} />
                                                    )
                                                : arrival.realtime_status === 'UPDATED'
                                                    ? (
                                                            <SensorsIcon style={{ fontSize: '1rem', animation: 'blink 2s linear infinite', marginRight: '5px' }} />
                                                        )
                                                    : (
                                                            <DepartureBoardIcon style={{ fontSize: '1rem', marginRight: '5px' }} />
                                                        )}
                                            {formatTime(arrival.arrival_realtime, arrival.departure_realtime, 'relative', t)}
                                        </Stack>
                                    )}
                                    secondary={getNextTime(arrival, arrivals, index, 'relative', t)}
                                    sx={{
                                        textAlign: 'right', // Ensures text alignment to the right
                                        color: arrival.realtime_status === 'CANCELED' ? 'red' : arrival.realtime_status === 'UPDATED' ? 'green' : theme.palette.text.primary,
                                        minWidth: '8rem', // Maintain the minimum width for consistent alignment
                                    }}
                                />
                            </ListItemButton>
                        ))
                    )
                : (
                        <ListItem key="none">
                            <ListItemText primary={t('No more arrivals are planned for today.')} key="0" />
                        </ListItem>
                    )}
        </List>
    )
}

export default ArrivalsList
