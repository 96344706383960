import FavoriteIcon from '@mui/icons-material/Favorite'
import LayersIcon from '@mui/icons-material/Layers'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import TranslateIcon from '@mui/icons-material/Translate'
import { Box, ListItemButton, Select, Typography, useColorScheme } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { useLocalStorage } from '@uidotdev/usehooks'
import { useTranslation } from 'react-i18next'
import AutomaticSheet from 'src/components/sheet/AutomaticSheet'
import SheetHeader from 'src/components/sheet/SheetHeader'
export const Route = createFileRoute('/settings')({
    component: Settings,
})

function Settings() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [language, setLanguage] = useLocalStorage('userLanguage')

    const { mode, setMode } = useColorScheme()
    return (
        <AutomaticSheet>
            <title>{t('Settings')}</title>
            <SheetHeader heading={t('Settings')} share={false} />
            <Box>

                <ListItemButton onClick={() => navigate({ to: '/filter' })}>
                    <LayersIcon sx={{ marginRight: 1, color: 'primary.main' }} />
                    <Box>
                        <Typography variant="body1" color="textprimary">{t('Filters')}</Typography>
                        <Typography variant="body2" color="textSecondary">{t('Visible modes of transport on map')}</Typography>
                    </Box>
                    <NavigateNextIcon sx={{ marginLeft: 'auto', color: 'primary.main' }} />
                </ListItemButton>

                <ListItemButton onClick={() => navigate({ to: '/pois' })} disabled>
                    <FavoriteIcon sx={{ marginRight: 1, color: 'primary.main' }} />
                    <Box>
                        <Typography variant="body1" color="textprimary">{t('Favourites')}</Typography>
                        <Typography variant="body2" color="textSecondary">{t('See and delete your favourite locationss')}</Typography>
                    </Box>
                    <NavigateNextIcon sx={{ marginLeft: 'auto', color: 'primary.main' }} />
                </ListItemButton>

                <SettingsItem
                    name={t('Language')}
                    icon={<TranslateIcon sx={{ color: 'primary.main' }} />}
                    control={(
                        <Select
                            size="small"
                            value={language}
                            onChange={(event) => {
                                setLanguage(event.target.value)
                            }}
                        >
                            <MenuItem value="en">English</MenuItem>
                            <MenuItem value="sl">Slovenščina</MenuItem>
                        </Select>
                    )}
                />
                <SettingsItem
                    name={t('Theme')}
                    icon={<LayersIcon sx={{ color: 'primary.main' }} />}
                    control={(
                        <Select
                            size="small"
                            value={mode}
                            onChange={(event) => {
                                setMode(event.target.value as 'system' | 'light' | 'dark')
                            }}
                        >
                            <MenuItem value="system">{t('theme_system')}</MenuItem>
                            <MenuItem value="light">{t('theme_light')}</MenuItem>
                            <MenuItem value="dark">{t('theme_dark')}</MenuItem>
                        </Select>
                    )}
                />
            </Box>
        </AutomaticSheet>
    )
}


const SettingsItem = ({ name, icon, description, control }) => {
    return (
        <Box display="flex" flexDirection="row" alignItems="center" sx={{ width: '100%', height: '3rem', p: 2 }}>
            {icon}
            <Box sx={{ flex: 1, p: 1 }}>
                <Typography variant="body1">{name}</Typography>
                <Typography variant="body2" color="textSecondary">{description}</Typography>
            </Box>
            {control}
        </Box>
    )
}