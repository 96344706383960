import BusAlertIcon from '@mui/icons-material/BusAlert'
import {
    Box,
    Checkbox,
    CircularProgress,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material'
import { createFileRoute } from '@tanstack/react-router'
import { DateTime } from 'luxon'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MapRenderContext } from 'src/contexts/MapRenderContext'
import {
    useGetTripGeometryTripsTripIdGeometryGet,
    useGetTripTripsTripIdGet,
    useGetVehicleLocationsTripsTripIdVehiclesGet,
} from 'src/generated/brezavta/queries'

import AlertDrawer from 'src/components/AlertDrawer'
import { LineChip } from 'src/components/LineChip'
import AutomaticSheet from 'src/components/sheet/AutomaticSheet'
import SheetHeader from 'src/components/sheet/SheetHeader'
import TripArrivals from 'src/components/TripArrivals'

export const Route = createFileRoute('/trip/$tripId')({
    component: Trip,
})

const initialState = {
    trip: null,
    polyline: null,
    vehicleLocations: null,
    loading: true,
    error: false,
    color: '#000000',
}

function tripReducer(state, action) {
    switch (action.type) {
        case 'SET_TRIP':
            return { ...state, trip: action.payload, loading: false }
        case 'SET_POLYLINE':
            return { ...state, polyline: action.payload }
        case 'SET_VEHICLE_LOCATIONS':
            return { ...state, vehicleLocations: action.payload }
        case 'SET_COLOR':
            return { ...state, color: action.payload }
        case 'SET_ERROR':
            return { ...state, error: true, loading: false }
        default:
            throw new Error('Unknown action type')
    }
}

const VEHICLE_LOCATION_REFRESH_INTERVAL = 3000

function Trip() {
    const [state, dispatch] = useReducer(tripReducer, initialState)
    const { map, mapLoaded } = useContext(MapRenderContext)
    const { tripId } = Route.useParams()
    const { t } = useTranslation()

    const [menuItems, setMenuItems] = useState<MenuItem[]>([])
    /** Whether the map should follow the vehicle location */
    const [isFollowing, setIsFollowing] = useState(true)

    const { data, error, refetch } = useGetTripTripsTripIdGet({
        path: { trip_id: tripId },
        query: { date: DateTime.now().toFormat('yyyyMMdd') },
    })
    const { data: geodata } = useGetTripGeometryTripsTripIdGeometryGet({
        path: { trip_id: tripId },
    })
    const { data: vehicleLocation, refetch: refetchVehicles } = useGetVehicleLocationsTripsTripIdVehiclesGet({
        path: { trip_id: tripId },
    })

    // Periodically refetch data, but clean up the interval on unmount
    useEffect(() => {
        const interval = setInterval(() => {
            refetch()
            refetchVehicles()
        }, VEHICLE_LOCATION_REFRESH_INTERVAL)

        return () => {
            clearInterval(interval) // Cleanup interval on component unmount
        }
    }, [refetch, refetchVehicles])

    // Handle trip data loading and error states
    useEffect(() => {
        if (data) {
            dispatch({ type: 'SET_TRIP', payload: data })
            dispatch({ type: 'SET_COLOR', payload: data.color })
        }
        else if (error) {
            dispatch({ type: 'SET_ERROR' })
        }
    }, [data, error])

    // Update polyline data
    useEffect(() => {
        if (geodata) {
            dispatch({ type: 'SET_POLYLINE', payload: geodata })
        }
    }, [geodata])

    // Update vehicle locations
    useEffect(() => {
        if (vehicleLocation) {
            dispatch({ type: 'SET_VEHICLE_LOCATIONS', payload: vehicleLocation })
        }
    }, [vehicleLocation])

    const { trip, vehicleLocations } = state // Define trip here

    const vehicleLayer = map?.current?.getLayer('vehicles')
    const polylineLayer = map?.current?.getLayer('tripPolyline')

    // Update the map with the new polyline data
    useEffect(() => {
        if (!mapLoaded || !state.polyline) return

        const mapInstance = map!.current!

        if (mapInstance.getSource('tripPolyline')) {
            const sourceData = {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'LineString',
                            coordinates: state.polyline.coordinates,
                        },
                        properties: {
                            color: state.polyline.properties.color || state.color,
                        },
                    },
                ],
            }
            mapInstance.getSource('tripPolyline')!.setData(sourceData)
        }
    }, [state.polyline, state.color, map, polylineLayer, mapLoaded])

    // Update the map with the new vehicle location
    useEffect(() => {
        if (!mapLoaded || !state.polyline) return

        const mapInstance = map!.current!

        if (mapInstance.getSource('vehicles')) {
            const sourceData = {
                type: 'FeatureCollection',
                features: state.vehicleLocations.map(vehicle => ({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [vehicle.lon, vehicle.lat],
                    },
                    properties: {
                        color: vehicle.color || state.color,
                    },
                })),
            }
            mapInstance.getSource('vehicles')!.setData(sourceData)

            if (state.vehicleLocations.length > 0 && isFollowing) {
                mapInstance.flyTo({
                    center: [state.vehicleLocations[0].lon, state.vehicleLocations[0].lat],
                    zoom: 15,
                })
            }
        }
    }, [state.vehicleLocations, state.color, map, vehicleLayer, isFollowing])

    // Check if the trip has alerts
    const hasAlerts = (trip) => {
        return trip?.stop_times?.some(stopTime => stopTime.alerts.length > 0)
    }

    useEffect(() => {
        if (state.vehicleLocations && state.vehicleLocations.length == 1) {
            setMenuItems([(
                <MenuItem onClick={() => setIsFollowing(f => !f)}>
                    <Checkbox style={{ paddingLeft: 0 }} checked={isFollowing}></Checkbox>
                    {t('Follow vehicle')}
                </MenuItem>
            )])
        }
        else {
            setMenuItems([])
        }
    }, [setMenuItems, state.vehicleLocations, isFollowing, setIsFollowing])

    // Handle loading and error states
    if (state.loading) {
        return (
            <AutomaticSheet>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: window.innerHeight / 2 }}>
                    <CircularProgress />
                </Box>
            </AutomaticSheet>
        )
    }

    if (state.error) {
        return (
            <AutomaticSheet>
                <Stack style={{ padding: 5 }}>
                    {t('Error loading trip details.')}
                </Stack>
            </AutomaticSheet>
        )
    }

    return (
        <AutomaticSheet>
            <title>{trip?.route_short_name}</title>
            <Stack>
                <SheetHeader
                    heading={(
                        <>
                            <LineChip
                                text={trip?.route_short_name}
                                color={trip?.color}
                                textColor={trip?.text_color}
                                extraStyles={{ marginRight: '5px' }}
                            />
                            {trip?.trip_headsign}
                            {hasAlerts(trip) && <BusAlertIcon sx={{ color: 'orange' }} />}
                        </>
                    )}
                    menuItems={menuItems}
                >
                    {vehicleLocations.length > 0 && (
                        <Typography variant="subtitle2">
                            {t('Vehicle') + ': ' + vehicleLocations.map(x => x.vehicle.plate || x.vehicle.id).join(', ')}
                        </Typography>
                    )}
                </SheetHeader>

                <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <AlertDrawer tripData={trip} />
                    <TripArrivals tripData={trip} />
                </Box>
            </Stack>
        </AutomaticSheet>
    )
}

export default Trip
