import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ErrorRouteComponent, Link, RouterProvider, createRouter } from '@tanstack/react-router'
import React, { useEffect } from 'react'
import { MapRenderProvider } from './contexts/MapRenderContext'

import { App as capacitorApp } from '@capacitor/app'

import './App.css'

import Box from '@mui/material/Box'
import { useLocalStorage } from '@uidotdev/usehooks'
import { t } from 'i18next'
import AutomaticSheet from './components/sheet/AutomaticSheet'
import i18n from './i18n'
import { setupCapacitorNavigation } from './lib/capacitorStuff'
import { routeTree } from './routeTree.gen'

const queryClient = new QueryClient()
const router = createRouter({
    basepath: import.meta.env.VITE_BASE_PATH,
    routeTree,
    context: {
        queryClient,
    },
    defaultPreload: 'intent',
    defaultPreloadStaleTime: 0,
    defaultErrorComponent: (({ error }) => {
        return (
            <AutomaticSheet>
                <Box sx={{ p: 2 }}>
                    {t('An error has occured and the developers have been notified.')}
                    <br />
                    <Link to="/">{t('Go home')}</Link>
                </Box>
            </AutomaticSheet>
        )
    }) satisfies ErrorRouteComponent,
})
declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router
    }
}

const App: React.FC = () => {
    // Capacitor-specific stuff
    useEffect(() => {
        setupCapacitorNavigation(capacitorApp, router)
    // loadCapacitorGeolocation();
    }, [])

    const [language, _] = useLocalStorage('userLanguage', 'sl')

    useEffect(() => {
        i18n.changeLanguage(language)
    }, [language])

    return (
        <>
            <QueryClientProvider client={queryClient}>
                <MapRenderProvider>
                    <RouterProvider router={router} />
                    <ReactQueryDevtools initialIsOpen={false} />
                </MapRenderProvider>
            </QueryClientProvider>
        </>
    )
}

export default App
