import { createFileRoute } from '@tanstack/react-router'
import { useState } from 'react'
import PlanTimePicker, { DepartureTimeDef } from 'src/components/PlanTimePicker'
import AutomaticSheet from 'src/components/sheet/AutomaticSheet'

export const Route = createFileRoute('/info')({
    component: Info,
})

function Info() {
    console.debug('rendered Info')

    const [planTime, setPlanTime] = useState < DepartureTimeDef > ({
        datetime: undefined,
        departureOrArrival: 'DEPART',
        isNow: true,
    })

    return (
        <AutomaticSheet>
            <PlanTimePicker value={planTime} onChange={setPlanTime} />
        </AutomaticSheet>
    )
}
