import { useNavigate } from '@tanstack/react-router'
import maplibregl from 'maplibre-gl'
import { useContext, useEffect } from 'react'
import { MapRenderContext } from 'src/contexts/MapRenderContext'
import { useListStopsStopsGet } from 'src/generated/brezavta/queries'
import { Stop } from 'src/generated/brezavta/requests/types.gen'
import { ICON_BASE_SIZE } from 'src/lib/constants'
import { addRandomError, namespaceIconExpression, useSimpleCache } from 'src/lib/util'

const ICON_MIN_ZOOM = 11

// Function to set data for a specific agency
const setData = (map: maplibregl.Map, stops: Stop[], agency: string, navigate) => {
    const circleLayerId = `stops-circles-${agency.toLowerCase()}`
    const symbolLayerId = `stops-symbol-${agency.toLowerCase()}`

    // Check if source exists, if not, add it
    if (!map.getSource(circleLayerId)) {
        map.addSource(circleLayerId, {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        })
    }

    // Check if layer exists, if not, add it
    if (!map.getLayer(circleLayerId)) {
        map.addLayer({
            id: circleLayerId,
            type: 'circle',
            source: circleLayerId,
            maxzoom: ICON_MIN_ZOOM,
            paint: {
                'circle-radius': [
                    'interpolate', ['linear'],
                    ['zoom'],
                    // (zoom, size) pairs
                    1, 1,
                    5, 3,
                    ICON_MIN_ZOOM, 2,
                ],
                'circle-color': ['get', 'background_color'],
                'circle-opacity': ['step', ['zoom'], 0, 7, 0.14, 7.5, 0.25, 7.75, 0.5, 8, 1, 14, 0],
            },
        })
    }

    // Ensure the symbol layer exists
    if (!map.getLayer(symbolLayerId)) {
        map.addLayer({
            id: symbolLayerId,
            type: 'symbol',
            source: circleLayerId,
            minzoom: ICON_MIN_ZOOM,
            layout: {
                'icon-image': namespaceIconExpression(['get', 'icon']),
                'icon-size': [
                    'interpolate', ['linear'],
                    ['zoom'],
                    // (zoom, size) pairs
                    10, ICON_BASE_SIZE * 0.1,
                    13, ICON_BASE_SIZE * 1,
                ],
                'icon-allow-overlap': true,
                'icon-anchor': 'center',
                'text-field': '{name}',
                'text-anchor': 'top',
                'text-offset': [0, 1],
                'text-size': ['step', ['zoom'], 0, 18, 13],
                'text-padding': 1,
                'text-max-width': 8,
                'text-allow-overlap': true,
            },
            paint: {
                'text-color': ['get', 'background_color'],
                'icon-opacity': 1,
                'text-opacity': 1,
                'text-halo-blur': 0.5,
                'text-halo-color': '#fff',
                'text-halo-width': 3,
            },
        })

        map.on('mouseenter', symbolLayerId, () => {
            map.getCanvas().style.cursor = 'pointer'
        })

        map.on('mouseleave', symbolLayerId, () => {
            map.getCanvas().style.cursor = ''
        })

        map.on('click', symbolLayerId, (e) => {
            const id = e.features[0].properties.id
            navigate({ to: `/stop/${id}` })
        })
    }

    // Set the GeoJSON data for the source
    map.getSource(circleLayerId).setData({
        type: 'FeatureCollection',
        features: stops.map((stop) => {
            // Explicitly define all properties to avoid undefined errors
            const { lat, lon, name, gtfs_id, type, background_color, icon_color } = stop
            const adjustedLat = agency === 'CELEBUS' || agency === 'ARRIVAMP' ? addRandomError(lat) : lat
            const adjustedLon = agency === 'CELEBUS' || agency === 'ARRIVAMP' ? addRandomError(lon) : lon
            return {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [adjustedLon, adjustedLat],
                },
                properties: {
                    name,
                    agency,
                    type,
                    id: gtfs_id,
                    background_color,
                    icon_color,
                    icon: `${agency.toLowerCase()}_${type.toLowerCase()}`,
                },
            }
        }),
    })
}

const StopLayer = () => {
    const { map, mapLoaded } = useContext(MapRenderContext)
    const navigate = useNavigate()

    const { data } = useListStopsStopsGet()
    const stops = useSimpleCache(data, 'stops', [])

    useEffect(() => {
        if (!map || !map.current || !mapLoaded) return

        const uniqueAgencyIDs = ['LPP', 'IJPP', 'MARPROM', 'CELEBUS', 'ARRIVAMP']

        for (const agency of uniqueAgencyIDs) {
            console.log('Setting data for agency:', agency)
            const agencyStops = stops.filter(
                stop => stop.gtfs_id.split(':')[0].toUpperCase() === agency,
            )
            setData(map.current, agencyStops, agency, navigate)
        }
    }, [map, mapLoaded, stops, navigate])

    return null
}

export default StopLayer
