import maplibregl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'

import React, { useContext, useEffect } from 'react'
import { MapRenderContext } from 'src/contexts/MapRenderContext'
import { initPlanLayer } from 'src/lib/layers/planLayer'
import { createPulsingDot } from 'src/lib/util'

function hexToRgb(hex) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b
    })

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
            }
        : null
}

const Map: React.FC = () => {
    const { map, mapRef, setMapLoaded } = useContext(MapRenderContext)

    useEffect(() => {
        if (!map || !mapRef || !mapRef.current) return
        if (map.current) return

        let center = [14.505751, 46.056946]

        // try to get current user location
        navigator.geolocation.getCurrentPosition(
            (position) => {
                center = [position.coords.longitude, position.coords.latitude]
                console.log('center', center)
            },
            (error) => {
                console.error('Error getting location', error)
            },
        )

        map.current = new maplibregl.Map({
            container: mapRef.current,
            style: 'https://tiles.tracking.party/maps/streets-v2/style.json',
            center: center,
            zoom: 11,
            minZoom: 6.5,
            renderWorldCopies: false,
            hash: false,
            attributionControl: false,
        })

        window.map = map.current

        // add attribution
        map.current.addControl(new maplibregl.AttributionControl({
            compact: true,
        }), 'bottom-left')

        // add rotation control
        map.current.addControl(new maplibregl.NavigationControl({
            showZoom: false,
            showCompass: true,
            visualizePitch: true,
        }), 'top-right')

        // add geolocate control
        map.current.addControl(new maplibregl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true,
            },
            trackUserLocation: true,
            showUserLocation: true,
        }), 'bottom-right')

        // Add a custom renderer for the pulisng dot
        map.current.addImage('pulsing-dot', createPulsingDot(map.current), { pixelRatio: 5, sdf: true })

        // TODO: convert this to some kind of React thingy (context?)
        map.current.on('styledata', (e) => {
            if (!map.current!.getStyle() || !map.current!.getStyle().layers) return

            // read the layer data from localstorage 'mapFilterOptions'
            const filterOptions = JSON.parse(localStorage.getItem('mapFilterOptions') || '{}')
            if (!filterOptions) return

            // key is the part of layer name, value is the visibility
            for (const layer of map.current!.getStyle().layers) {
                for (const key in filterOptions) {
                    if (layer.id.includes(key.toLowerCase())) {
                        map.current!.setLayoutProperty(layer.id, 'visibility', filterOptions[key] ? 'visible' : 'none')
                    }
                }
            }
        })

        // When the map is done loading
        map.current.on('load', () => {
            // Filter transport-related layers from the base style
            if (map.current!.getLayer('Other POI'))
                map.current!.setFilter('Other POI', [
                    'all',
                    ['==', '$type', 'Point'],
                    ['!=', 'class', 'bus'],
                    ['!=', 'class', 'rail'],
                    ['!=', 'class', 'railway'],
                ])

            if (map.current!.getLayer('Station')) {
                map.current!.setLayoutProperty('Station', 'visibility', 'none')
            }

            // Add our spritesheet (prefix ojpp:)
            map.current?.addSprite('ojpp', import.meta.env.VITE_BASE_PATH + '/sprites/sprites')

            // Prepare additional layers
            initPlanLayer(map.current!)

            // Signal to React components that the map is fully loaded
            setMapLoaded(true)
        })
    })

    return (
        <>
            <div id="map-container" ref={mapRef} style={{ height: '100%', width: '100%' }} />
        </>
    )
}

export default Map
