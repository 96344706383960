import { Box, Chip, CircularProgress, List, ListItem, OutlinedInput, Stack, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { DateTime } from 'luxon'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ArrivalsList from 'src/components/ArrivalsList'
import { LineChip } from 'src/components/LineChip'
import AutomaticSheet from 'src/components/sheet/AutomaticSheet'
import SheetHeader from 'src/components/sheet/SheetHeader'
import { MapRenderContext } from 'src/contexts/MapRenderContext'
import { useGetStopStopsStopIdGet, useListScheduleStopsStopIdScheduleDateGet } from 'src/generated/brezavta/queries'

import { formatTime } from 'src/lib/util'

export const Route = createFileRoute('/stop/$stopId')({
    component: Stop,
})

function Stop() {
    const navigate = useNavigate()
    const { stopId } = Route.useParams()
    const { t } = useTranslation()
    const theme = useTheme()

    const { map, mapRef } = useContext(MapRenderContext)

    const [tab, setTab] = useState('arrivals')
    const [centered, setCentered] = useState(false)

    const todayStr = DateTime.local({ zone: 'Europe/Ljubljana' }).toFormat('yyyyMMdd')
    const [scheduleDate, setScheduleDate] = useState(todayStr)

    const { data: arrivalsData, isLoading: isArrivalsLoading } = useGetStopStopsStopIdGet({ path: { stop_id: stopId }, query: { date: todayStr, current: true } })
    const { data: scheduleData, isLoading: isScheduleLoading, refetch: refetchSchedule } = useListScheduleStopsStopIdScheduleDateGet({ path: { stop_id: stopId, date: scheduleDate } })

    // Center map on stop location
    useEffect(() => {
        if (map && mapRef && arrivalsData && !centered) {
            map.current!.flyTo({
                center: [arrivalsData.lon, arrivalsData.lat],
                zoom: 15,
            })
            setCentered(true)
        }
    }, [map, mapRef, arrivalsData, centered])

    function getNextTime(arrival, arrivals, index, format, t) {
    // find next arrival which has the same route id and headsign
        for (let i = index + 1; i < arrivals.length; i++) {
            if (arrival.route_id === arrivals[i].route_id && arrival.trip_headsign === arrivals[i].trip_headsign) {
                return t('Next') + ' ' + formatTime(arrivals[i].arrival_realtime, arrivals[i].departure_realtime, format, t)
            }
        }
        return t('Last trip')
    }

    function isPassed(seconds) {
        let now = DateTime.local({ zone: 'Europe/Ljubljana' })
        now = parseInt(now.toFormat('HH')) * 60 * 60 + parseInt(now.toFormat('mm')) * 60 + parseInt(now.toFormat('ss'))
        const time = seconds - now

        if (time < 0 && todayStr === scheduleDate) {
            return true
        }
        return false
    }

    if (isArrivalsLoading) {
        return (
            <AutomaticSheet>
                <Box sx={{ display: 'flex', height: window.innerHeight / 2, justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            </AutomaticSheet>
        )
    }

    return (
        <AutomaticSheet>
            <title>{arrivalsData?.name}</title>
            <SheetHeader
                heading={arrivalsData?.name}
            />
            <Stack sx={{ paddingX: 2, paddingBottom: 2 }}>
                <Box>
                    <ToggleButtonGroup
                        value={tab}
                        exclusive
                        fullWidth
                        size="small"
                        onChange={event => setTab(event.target.value)}
                        sx={{ justifyContent: 'center', color: 'primary.main' }}
                    >
                        <ToggleButton value="arrivals">{t('Arrivals')}</ToggleButton>
                        <ToggleButton value="timetable">{t('Timetable')}</ToggleButton>
                    </ToggleButtonGroup>

                </Box>
            </Stack>
            <div style={{ display: tab === 'arrivals' ? 'block' : 'none' }}>
                <ArrivalsList
                    arrivals={arrivalsData?.arrivals}
                    formatTime={formatTime}
                    getNextTime={getNextTime}
                />
            </div>
            <div style={{ display: tab === 'timetable' ? 'block' : 'none' }}>
                <Box
                    sx={{
                        marginX: 2,
                    }}
                >

                    <OutlinedInput
                        type="date"
                        fullWidth
                        size="small"
                        value={scheduleDate.substring(0, 4) + '-' + scheduleDate.substring(4, 6) + '-' + scheduleDate.substring(6, 8)}
                        onChange={(e) => {
                            setScheduleDate(e.target.value.replace(/-/g, ''))
                            refetchSchedule()
                        }}
                    />
                </Box>

                <List>
                    {
                        (scheduleData?.schedule || []).map((item, index) => {
                            return (
                                <>
                                    <ListItem key={index + 'l'}>
                                        <Stack direction="row" spacing={1} sx={{ width: '100%', verticalAlign: 'center' }}>
                                            <LineChip
                                                text={item.route_short_name}
                                                color={item.route_color_background}
                                                textColor={item.route_color_text}
                                            />
                                            <Typography component="span" variant="body2">{item.trip_headsign.toUpperCase()}</Typography>
                                        </Stack>
                                    </ListItem>
                                    { /* flex so it forms a box */}
                                    <ListItem key={index + 's'} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                        {
                                            item.times.map((stop_time, index) => {
                                                return (
                                                    <Chip
                                                        key={index}
                                                        variant="outlined"
                                                        label={<span style={{ animation: stop_time.realtime && !isPassed(stop_time.departure_time) ? 'blink 2s linear infinite' : 'none' }}>{formatTime(stop_time.departure_time, stop_time.departure_time, 'absolute', t)}</span>}
                                                        sx={{ opacity: isPassed(stop_time.departure_time) ? 0.5 : 1, color: stop_time.realtime && !isPassed(stop_time.departure_time) ? theme.palette.success.main : theme.palette.text.primary, margin: '.125rem' }}
                                                        onClick={() => {
                                                            navigate({
                                                                to: `/trip/${stop_time.trip_id}`,
                                                            })
                                                        }}
                                                    />
                                                )
                                            })

                                        }
                                    </ListItem>
                                </>
                            )
                        })
                    }
                </List>
            </div>
        </AutomaticSheet>
    )
}

export default Stop
