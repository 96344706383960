import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, List, ListItemButton, Typography } from '@mui/material'
import { createFileRoute, useNavigate, useRouterState } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import AutomaticSheet from 'src/components/sheet/AutomaticSheet'

import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import HomeIcon from '@mui/icons-material/Home'
import SchoolIcon from '@mui/icons-material/School'
import StarIcon from '@mui/icons-material/Star'
import WorkIcon from '@mui/icons-material/Work'

const iconList = [
    { name: 'home', component: <HomeIcon sx={{ color: 'primary.main' }} /> },
    { name: 'work', component: <WorkIcon sx={{ color: 'primary.main' }} /> },
    { name: 'school', component: <SchoolIcon sx={{ color: 'primary.main' }} /> },
    { name: 'workout', component: <FitnessCenterIcon sx={{ color: 'primary.main' }} /> },
    { name: 'star', component: <StarIcon sx={{ color: 'primary.main' }} /> },
]

export const Route = createFileRoute('/plan/poi/$selectType/')({
    component: SelectPOI,
})

function SelectPOI() {
    const navigate = useNavigate()
    const state = useRouterState()

    const { selectType } = Route.useParams()

    const [pois, setPois] = useState([])

    useEffect(() => {
        const storedPOIs = localStorage.getItem('pois')
        if (storedPOIs) {
            const parsedPOIs = JSON.parse(storedPOIs)

            // Attach correct icons to each POI based on `iconList`
            const updatedPOIs = parsedPOIs.map((poi) => {
                const foundIcon = iconList.find(icon => icon.name === poi.icon)
                return {
                    ...poi,
                    component: foundIcon ? foundIcon.component : <StarIcon />,
                }
            })

            setPois(updatedPOIs)
        }
    }, [])

    const handleSelectPOI = (poi) => {
        const selectedData = {
            latitude: poi.data.lat,
            longitude: poi.data.lon,
            name: poi.name,
            type: selectType,
        }

        const navigateState = {
            originData: state?.location.state?.originData,
            destinationData: state?.location.state?.destinationData,
            departureTime: state?.location.state?.departureTime,
            modes: state?.location.state?.modes,
        }

        if (selectType === 'origin') {
            navigate({
                to: '/plan',
                state: {
                    ...navigateState,
                    originData: selectedData,
                },
            })
        }
        else {
            navigate({
                to: '/plan',
                state: {
                    ...navigateState,
                    destinationData: selectedData,
                },
            })
        }
    }

    return (
        <AutomaticSheet>
            <List>
                {pois.map(poi => (
                    <ListItemButton
                        key={poi.id}
                        onClick={() => handleSelectPOI(poi)}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            sx={{
                                width: '100%',
                                height: '3rem',
                                borderBottom: '1px solid #f0f0f0',
                            }}
                        >
                            <Box marginRight={1}>
                                {poi.component}
                            </Box>
                            <Box>
                                <Typography variant="body1">{poi.name}</Typography>
                            </Box>
                            <Box sx={{ marginLeft: 'auto' }}>
                                <NavigateNextIcon sx={{ color: 'primary.main' }} />
                            </Box>
                        </Box>
                    </ListItemButton>
                ))}
            </List>
        </AutomaticSheet>
    )
}

export default SelectPOI
