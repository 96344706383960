import { useWindowSize } from '@uidotdev/usehooks'
import BottomSheetPadded from './BottomSheetPadded'
import SideSheet from './SideSheet'

const AutomaticSheet = ({ children }) => {
    const windowSize = useWindowSize()

    if (windowSize.width !== null && windowSize.width > windowSize.height!)
        return (
            <SideSheet>
                {children}
            </SideSheet>
        )
    else
        return (
            <BottomSheetPadded>
                {children}
            </BottomSheetPadded>
        )
}

export default AutomaticSheet
