export function initPlanLayer(map: maplibregl.Map) {
    map.addSource('planView', {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [],
        },
    })
    map.addLayer({
        id: 'planView',
        type: 'line',
        source: 'planView',
        layout: {
            'line-join': 'round',
        },
        paint: {
            'line-color': ['get', 'color'],
            'line-width': 5,
            'line-opacity': 0.75,
        },
    })
    map.addSource('planVehicleLocations', {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [],
        },
    })
    map.addLayer({
        id: 'planVehicleLocations',
        type: 'symbol',
        source: 'planVehicleLocations',
        layout: {
            'icon-image': 'pulsing-dot',
            'icon-allow-overlap': true,
        },
        paint: {
            'icon-color': ['get', 'color'],
            'icon-halo-color': '#fff',
            'icon-halo-width': 1,
        },
    })
}
