import React, { useContext, useEffect } from 'react'
import { MapRenderContext } from 'src/contexts/MapRenderContext'
import { useGetParkingParkingGet } from 'src/generated/brezavta/queries'
import { ICON_BASE_SIZE } from 'src/lib/constants'
import { namespaceIconExpression } from 'src/lib/util'

const ParkingLayer: React.FC = () => {
    const { map, mapLoaded } = useContext(MapRenderContext)

    const { data: parkingsData, refetch } = useGetParkingParkingGet()

    // refetch once every 1 minute
    useEffect(() => {
        const interval = setInterval(() => {
            refetch()
        }, 60000)

        return () => clearInterval(interval)
    }, [refetch])

    useEffect(() => {
        if (!map || !map.current || !mapLoaded) return

        // Add the source and layers
        let source = map.current.getSource('parkings')
        if (!source) {
            addParkingLayer(map.current)
        }
        source = map.current.getSource('parkings')!

        // Update the data
        const parkings = parkingsData || []
        source.setData({
            type: 'FeatureCollection',
            features: parkings.map(x => ({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [x.lon, x.lat],
                },
                properties: {
                    icon: `parking_${x.icon.toLocaleLowerCase()}`,
                    detail_icon: `parking_detail_${x.icon.toLocaleLowerCase()}`,
                    title: x.name,
                    capacity: x.capacity,
                    available: x.available || '-',
                },
            })),
        })
    }, [map, parkingsData, mapLoaded])

    return (
        <></>
    )
}

export default ParkingLayer

const DETAIL_MIN_ZOOM = 14

function addParkingLayer(map: import('maplibre-gl').Map) {
    // map.once('styledata', () => {
    // console.log("Map has loaded");

    // Create or update source and layer
    if (!map.getSource('parkings')) {
        map.addSource('parkings', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        })
    }

    if (!map.getLayer('parkings-circles')) {
        map.addLayer({
            id: 'parkings-circles',
            type: 'symbol',
            source: 'parkings',
            maxzoom: DETAIL_MIN_ZOOM,
            layout: {
                'icon-image': namespaceIconExpression(['get', 'icon']),
                'icon-size': [
                    'interpolate', ['linear'],
                    ['zoom'],
                    // (zoom, size) pairs
                    12, ICON_BASE_SIZE * 0.1,
                    13, ICON_BASE_SIZE * 1,
                ],
                'icon-allow-overlap': true,
                'icon-anchor': 'left',
            },
        })
        map.addLayer({
            id: 'parkings-details',
            type: 'symbol',
            source: 'parkings',
            minzoom: DETAIL_MIN_ZOOM,
            layout: {
                'icon-image': namespaceIconExpression(['get', 'detail_icon']),
                // interpolate based on zoom:
                // 0.15 for all zoom levels above 15.5
                'icon-size': ICON_BASE_SIZE * 1,
                'text-field': ['format',
                    ['get', 'available'],
                    { 'text-color': ['case', ['==', ['get', 'available'], '-'], 'gray', ['==', ['get', 'available'], 0], 'red', 'green'] },
                    '\n',
                    { 'text-color': 'gray' },
                    ['get', 'capacity'],
                    { 'text-color': 'black' },
                ],
                'text-font': ['Noto Sans Regular'],
                'text-size': ['step', ['zoom'], 0, 15, 11],
                // float text to right of icon
                'text-offset': [3.5, 0],
                'icon-allow-overlap': true,
                'icon-anchor': 'left',
                'text-allow-overlap': true,
            },
        })
    }
    // });
}
