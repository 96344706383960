import { Box, CircularProgress } from '@mui/material'
import List from '@mui/material/List'
import { createFileRoute, useRouterState } from '@tanstack/react-router'
import { useContext, useEffect, useState } from 'react'
import ItineraryLeg from 'src/components/plan/PlanItineraryLeg'
import AutomaticSheet from 'src/components/sheet/AutomaticSheet'
import { MapRenderContext } from 'src/contexts/MapRenderContext'
import { useGetPlanPlanTransportModesFromLonLatToLonLatGet, useGetVehicleLocationsTripsTripIdVehiclesGet } from 'src/generated/brezavta/queries'
import { Plan } from 'src/generated/brezavta/requests'
import { getColorForMode } from 'src/lib/modes'
import { calculateBoundsForLegs, useLayerData } from 'src/lib/util'

export const Route = createFileRoute('/plan/$mode/$coordinates/$datetime/$type/$page/$itinIndex')({
    component: () => <ShowPlanDetails />,
})

const VEHICLE_REFRECH_INTERVAL_MS = 15000

function ShowPlanDetails() {
    const { mode, coordinates, datetime, page, itinIndex } = Route.useParams()
    const state = useRouterState()
    const { map, mapLoaded } = useContext(MapRenderContext)

    const [data, setData] = useState<Plan>(null)

    const { data: planData } = useGetPlanPlanTransportModesFromLonLatToLonLatGet({
        path: {
            from_lon_lat: coordinates!.split(';')[0],
            to_lon_lat: coordinates!.split(';')[1],
            transport_modes: mode,
        },
        query: {
            date: datetime!.split('T')[0],
            time: datetime!.split('T')[1].substring(0, 5),
            page_cursor: page == 'null' ? '' : page,
        },
    })

    const [tripIds, setTripIds] = useState<string>('')

    const { data: vehicleData, refetch: vehicleRefetch } = useGetVehicleLocationsTripsTripIdVehiclesGet({
        path: { trip_id: tripIds },
    })

    // Initialize the Plan from router state
    useEffect(() => {
        if (!data && state.location.state?.plan!) {
            setData(state.location.state.plan)
        }
    }, [state, data])

    // Update the Plan when the request returns
    // TODO: how to avoid making a request when we already have router state
    useEffect(() => {
        if (planData)
            setData(planData.plans[itinIndex])
    }, [planData, setData, itinIndex])

    /*
    ROUTE RENDERING ON MAP
  */

    const layerData = data && {
        type: 'FeatureCollection',
        features: data.legs.map(itinerary => ({
            type: 'Feature',
            geometry: itinerary.geometry,
            properties: {
                color: getColorForMode(itinerary),
            },
        })),
    }

    useLayerData('planView', layerData, map, mapLoaded)

    // set map bounds to fit the route
    useEffect(() => {
        if (!mapLoaded || !data) return
        const bounds = calculateBoundsForLegs(data.legs)
        map!.current!.fitBounds(bounds, {
            padding: 50,
        })
    }, [data, mapLoaded])

    /*
    VEHICLE LOCATIONS
  */

    // Extract trip IDs for fethching vehicle locations
    useEffect(() => {
        if (!data) return
        // Also filters out undefined routes
        const tripIds = data.legs.map(itinerary => itinerary.trip?.gtfs_id).filter(id => id)
        setTripIds(tripIds.join(','))
    }, [data])

    const vehicleLocationsData = vehicleData && {
        type: 'FeatureCollection',
        features: vehicleData.map(vehicle => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [vehicle.lon, vehicle.lat],
            },
            properties: {
                color: vehicle.color,
            },
        })),
    }
    useLayerData('planVehicleLocations', vehicleLocationsData, map, mapLoaded)

    // refetch locations once every 15 seconds
    useEffect(() => {
        if (tripIds) {
            setTimeout(() => {
                vehicleRefetch()
            }, VEHICLE_REFRECH_INTERVAL_MS)
        }
    }, [tripIds])

    if (!data) {
        return (
            <AutomaticSheet>
                <Box sx={{ display: 'flex', height: window.innerHeight / 2, justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            </AutomaticSheet>
        )
    }

    return (
        <AutomaticSheet>
            <List sx={{ width: '100%', overflowX: 'hidden' }}>
                {
                    data.legs.map((itinerary: any, index: number) => {
                        const nextLeg = data.legs[index + 1]
                        const previousLeg = data.legs[index - 1]
                        return (
                            <div key={index}>
                                <ItineraryLeg type={itinerary.type} leg={itinerary} nextLeg={nextLeg} previousLeg={previousLeg}
                                isFirst={index === 0} isLast={index === data.legs.length - 1} />
                            </div>
                        )
                    })
                }
            </List>
        </AutomaticSheet>
    )
}
