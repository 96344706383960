import React, { useContext, useEffect } from 'react'
import { MapRenderContext } from 'src/contexts/MapRenderContext'

const TripVehicleLayer: React.FC = () => {
    const { map, mapRef } = useContext(MapRenderContext)

    useEffect(() => {
        if (!map || !map.current || !mapRef || !mapRef.current) {
            console.error('Map or mapRef is not available')
            return
        }

        // Ensure the source and layers are added once the map style is loaded
        const addLayersAndSources = () => {
            // Check and add trip polyline source/layer
            if (!map.current.getSource('tripPolyline')) {
                map.current.addSource('tripPolyline', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    },
                })

                map.current.addLayer({
                    id: 'tripPolyline',
                    type: 'line',
                    source: 'tripPolyline',
                    paint: {
                        'line-color': ['get', 'color'],
                        'line-width': 5,
                        'line-opacity': 0.75,
                    },
                })
            }

            // Check and add vehicles source/layer
            if (!map.current.getSource('vehicles')) {
                map.current.addSource('vehicles', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    },
                })

                map.current.addLayer({
                    id: 'vehicles',
                    type: 'symbol',
                    source: 'vehicles',
                    layout: {
                        'icon-image': 'pulsing-dot',
                        'icon-allow-overlap': true,
                    },
                    paint: {
                        'icon-color': ['get', 'color'],
                        'icon-halo-color': '#fff',
                        'icon-halo-width': 1,
                    },
                })
            }
        }

        // If the map style is already loaded, add sources and layers
        if (map.current.isStyleLoaded()) {
            addLayersAndSources()
        }
        else {
            // Otherwise, add an event listener to wait for the style to load
            map.current.once('styledata', addLayersAndSources)
        }
    }, [map, mapRef]) // Ensure the effect runs when map or mapRef changes

    return null // No visual component is needed
}

export default TripVehicleLayer
