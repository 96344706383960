import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ScheduleIcon from '@mui/icons-material/Schedule'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Typography,
} from '@mui/material'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export type DepartureTimeDef = {
    departureOrArrival: 'ARRIVE' | 'DEPART'
    datetime: undefined
    isNow: true
} | {
    departureOrArrival: 'ARRIVE' | 'DEPART'
    datetime: DateTime
    isNow: false
}

type DepartureTimeProps = {
    value: DepartureTimeDef
    onChange: (newValues: DepartureTimeDef) => void
}

const PlanTimePicker = ({ value, onChange }: DepartureTimeProps) => {
    const { t } = useTranslation()

    const [isOpen, setIsOpen] = useState(false)
    // const [departureOrArrival, setdepartureOrArrival] = useState(value.departureOrArrival);
    // const [datetimeStr, setDatetimeStr] = useState(value.datetime);

    // useEffect(() => {
    //   // If the initial value changes, update the state
    //   if (value.departureOrArrival !== departureOrArrival) setdepartureOrArrival(value.departureOrArrival);
    //   if (value.datetime !== datetimeStr) setDatetimeStr(value.datetime);
    // }, [value, setdepartureOrArrival, setDatetimeStr]);

    const toggleOpen = () => {
        setIsOpen(prev => !prev)
    }

    function handleChange() {
        value = { ...value }
        onChange(value)
    }

    return (
        <Accordion expanded={isOpen} sx={{ width: '100%', boxShadow: 'none' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleOpen} sx={{ padding: 0, color: 'primary.main' }}>
                <Stack direction="row" spacing={1}>
                    <ScheduleIcon />
                    <Typography>{generateDisplayText(value)}</Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack direction="row" justifyContent="center" spacing={1}>
                    <Select
                        size="small"
                        value={value.departureOrArrival}
                        onChange={(event) => {
                            value.departureOrArrival = event.target.value
                            handleChange()
                        }}
                    >
                        <MenuItem value="DEPART">{t('Departure')}</MenuItem>
                        <MenuItem value="ARRIVE">{t('Arrival')}</MenuItem>
                    </Select>
                    <OutlinedInput
                        type="datetime-local"
                        value={value.datetime?.toISO({ includeOffset: false }) || ''}
                        size="small"
                        onChange={(e) => {
                            value.datetime = DateTime.fromISO(e.target.value).setZone('Europe/Ljubljana', { keepLocalTime: true })
                            value.isNow = false
                            handleChange()
                        }}
                    />
                    <Button
                        variant="outlined"
                        onClick={(e) => {
                            value.isNow = true
                            value.datetime = undefined
                            handleChange()
                        }}
                    >
                        {t('Now')}
                    </Button>
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
}

function generateDisplayText({ departureOrArrival, datetime, isNow }: DepartureTimeDef) {
    const { t } = useTranslation()

    const basedepartureOrArrival = departureOrArrival === 'DEPART' ? t('Depart') : t('Arrive')
    if (isNow) {
        return `${basedepartureOrArrival} ${t('now')}`
    }

    const today = DateTime.local({ zone: 'Europe/Ljubljana' }).toFormat('yyyy-MM-dd')
    const tomorrow = DateTime.local({ zone: 'Europe/Ljubljana' }).plus({ days: 1 }).toFormat('yyyy-MM-dd')

    let dayText = ''
    if (datetime.toFormat('yyyy-MM-dd') === today) {
        dayText = t('today')
    }
    else if (datetime.toFormat('yyyy-MM-dd') === tomorrow) {
        dayText = t('tomorrow')
    }
    else {
        dayText = datetime.toFormat('dd.MM.')
    }

    return `${basedepartureOrArrival} ${dayText} ${t('at')} ${datetime.toFormat('HH:mm')}`
}

export default PlanTimePicker
