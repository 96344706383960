import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEN from 'src/locales/en/translation.json'
import translationSL from 'src/locales/sl/translation.json'

// the translations
const resources = {
    en: {
        translation: translationEN,
    },
    sl: {
        translation: translationSL,
    },
}

i18n.use(initReactI18next).init({
    resources,
    // load from localStorage if present or default to 'sl'
    lng: localStorage.getItem('language') || 'sl',

    keySeparator: false,

    interpolation: {
        escapeValue: false,
    },
})

export default i18n
