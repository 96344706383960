/**
 * Load the Capacitor Geolocation library and patch navigator.geolocation to use it
 */
export async function loadCapacitorGeolocation() {
    try {
        const { Geolocation } = await import('@capacitor/geolocation')
        // Use Geolocation here
        console.log('Geolocation is loaded:', Geolocation)
        // set default navigator.geolocation
        navigator.geolocation = {
            getCurrentPosition: (successCallback, errorCallback, options) => {
                Geolocation.getCurrentPosition(options).then(
                    result => successCallback(result),
                    error => errorCallback(error),
                )
            },
            watchPosition: (successCallback, errorCallback, options) => {
                const id = Geolocation.watchPosition(options, (result) => {
                    successCallback(result)
                }, (error) => {
                    errorCallback(error)
                })
                return id
            },
            clearWatch: (id) => {
                Geolocation.clearWatch({ id })
            },
        }
    }
    catch (e) {
        console.error('Failed to load Geolocation:', e)
    // Handle the failure accordingly
    }
}

/**
 * Bind the hardware/OS back button to navigate back in the app
 */
export function setupCapacitorNavigation(capacitorApp, router) {
    capacitorApp.addListener('backButton', (data) => {
    // Handle the back button
        console.log('Back button pressed')
        // if there is no history, exit the app
        if (router.history.index === 0) {
            capacitorApp.exitApp()
        }
        console.log('going back', JSON.stringify(window.history))
        window.history.go(-1)
    })
}
