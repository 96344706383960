import { CssBaseline } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { createRootRoute, Outlet } from '@tanstack/react-router'
import Map from 'components/map/Map'
import MicromobilityLayer from 'components/map/MicromobilityLayer'
import ParkingLayer from 'components/map/ParkingLayer'
import StopLayer from 'components/map/StopLayer'
import Navbar from 'components/Navbar'
import BackButton from 'src/components/BackButton'
import TripVehicleLayer from 'src/components/map/TripVehicleLayer'

const Root = () => {
    const theme = createTheme({
        colorSchemes: {
            dark: true,
        },
        palette: {
            primary: {
                main: import.meta.env.VITE_THEME_COLOR_PRIMARY,
                contrastText: '#fff',
            },
            secondary: {
                main: import.meta.env.VITE_THEME_COLOR_SECONDARY,
                contrastText: '#fff',
            },
        },
    })
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ flex: 1 }} id="app-inner">
                    {/* Hacky way to make Apple users happy */}
                    <BackButton />

                    <Map />
                    <StopLayer />
                    <ParkingLayer />
                    <MicromobilityLayer />
                    <TripVehicleLayer />
                </div>
                <Outlet />
                <Navbar />
            </div>
        </ThemeProvider>
    )
}

export const Route = createRootRoute({
    component: Root,
})
