import { Box, useTheme } from '@mui/material'
import { ReactNode } from '@tanstack/react-router'
import { useContext, useEffect, useRef } from 'react'
import { MapRenderContext } from 'src/contexts/MapRenderContext'

const SideSheet = ({ children }: { children: ReactNode }) => {
    const bottomSheetRef = useRef()
    const theme = useTheme()

    const { map, mapRef } = useContext(MapRenderContext)

    // If we just switched from portrait or if the BottomSheet was loaded for whatever reason, force the map to full height
    useEffect(() => {
        mapRef!.current!.style.height = '100%'
        map!.current!.resize()
    }, [map, mapRef])

    return (
        <div
            ref={bottomSheetRef}
            style={{
                position: 'absolute',
                left: 0,
                bottom: 56, // Offset for bottom nav height
                width: '400px',
                height: 'calc(100% - 56px)',
                background: theme.palette.background.default,
                zIndex: 3,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box sx={{ overflowY: 'auto' }}>
                {children}
            </Box>
        </div>
    )
}

export default SideSheet
